import css from 'styled-jsx/css';

const styles = css`
  .facet-link {
    text-decoration: none;
    color: inherit;
  }
`;

export { styles };
