import { isTottusStandalone } from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import constants from '../../../config/constants';
import { withConfig } from '../../../utils/ConfigurationContext';
import { withLabels } from '../../../utils/LabelsContext';
import _ from '../../../utils/LodashImports';
import { redirect } from '../../../utils/RouteHandler';
import WithRouter from '../../../utils/WithRouter';
import noop from '../../../utils/noop';
import useIntersection from '../../../utils/useIntersection';
import Badges from '../../Badges/Badges';
import BestSellerRibbon from '../../BestSellerRibbon/BestSellerRibbon';
import ImageGallery from '../../ImageGallery/ImageGallery';
import MultipurposeBadge from '../../MultipurposeBadge/MultipurposeBadge';
import MyLists from '../../MyLists/MyLists';
import Prices from '../../Prices/Prices';
import ShippingDetails from '../../ShippingDetails/ShippingDetails';
import AddToCartButton from '../../ui/AddToCartButton/AddToCartButton';
import BodyCopy from '../../ui/BodyCopy/BodyCopy';
import Button from '../../ui/Button/Button';
import ColorSwatch from '../../ui/ColorSwatch/ColorSwatch';
import ImageGroup from '../../ui/ImageGroup/ImageGroup';
import RatingsVisual from '../../ui/RatingsVisual/RatingsVisual';
import Reviews from '../../ui/Reviews/Reviews';
import MarketplaceSpinner from '../../ui/Spinner/MarketplaceSpinner';
import Tab from '../../ui/Tab/Tab';
import TruncateText from '../../ui/TruncateText/TruncateText';

import WebStorage from '../../../utils/WebStorage';
import { toggleBodyClass } from '../../../utils/jsUtils';
import Tooltip, { TooltipText } from '../../ui/Tooltip/Tooltip';
import {
  buildPodUrl,
  capitalizeText,
  formatUrl,
  getCallToActionText,
  getSelectedColorIndex,
  getSellerIconClass,
  getUrlWithSponsoredProductClickData,
  isColorVariant,
} from '../podUtils';
import { GridPodGlobalStyles, GridPodStyles } from './GridPod.style';
import { LowestPlan } from './LowestPlan';
import { getPodStyles } from './helper';

const isActionPlaceHolderRequired = (deviceType) => {
  return deviceType === 'desktop';
};

const GridPod = ({
  product,
  labels,
  deviceType,
  lazyLoadImage,
  layout,
  isAddToCartInProgress,
  buttonLabel,
  clickForProductDetails,
  isSelected,
  appCtx,
  isPodActionButtonEnabled,
  sponsoredClick,
  collectionId,
  handlePodClick,
  isFirstPod,
  redirectToPDP,
  setIsAddToCartInProgress,
  index: productIndex,
  config,
  setShowOverlay,
}) => {
  const isTabApplicable = product.viewTemplate === 'CONNECT';
  const isMultipurposeBadgeApplicable = _.get(appCtx, 'siteConfig.toggles.isMultipurposeBadgeApplicable', false);
  const isMeatStickerApplicable = _.get(appCtx, 'siteConfig.toggles.isMeatStickerApplicable', false);
  const isImageGalleryEnabled = _.get(appCtx, 'siteConfig.toggles.isImageGalleryEnabled', false);
  const isImageGalleryMsgEnabled = _.get(appCtx, 'siteConfig.toggles.isImageGalleryMsgEnabled', false);
  const styles = getPodStyles(deviceType, layout);
  const { badges, meatStickers } = product;
  const hasStickers = isMeatStickerApplicable && !!meatStickers;
  const multipurposeBadges = _.get(product, 'multipurposeBadges', []);
  const [variantId, setVariantId] = useState(product.skuId);
  const [productUrl, setProductURL] = useState('');
  const [hoverState, setHoverState] = useState(false);
  const [lowestPlan, setLowestPlan] = useState(_.get(product, 'lowestPlan', null));
  const isSponsored = _.get(product, 'isSponsored', false);
  const sponsoredText = _.get(labels, 'SPONSORED_TEXT', 'Patrocinado');
  const podRef = useRef();
  const inViewport = useIntersection(podRef, '0px', '0.3', false);
  const categoryName = WithRouter('name');

  const { store } = appCtx;
  const isTottusSA = isTottusStandalone(store);
  const [closeToolTip, setCloseToolTip] = useState(true);
  useEffect(() => {
    if (inViewport) {
      sponsoredClick('impression');
    }
  }, [inViewport]);

  useEffect(() => {
    const showImageGalleryTutorial = WebStorage.getItem({
      name: 'showImgGalleryMsg',
      fromPersistence: true,
    });
    if (showImageGalleryTutorial !== 'false' && isImageGalleryMsgEnabled && deviceType !== 'desktop') {
      window.scrollTo(0, 0);
      toggleBodyClass(true, 'no-scroll');
      setCloseToolTip(false);
    } else {
      setCloseToolTip(true);
    }
  }, []);

  const getImagesForPod = (media) => {
    return isImageGalleryEnabled ? media : [media[0]];
  };

  const productHasMediaUrls = product.mediaUrls && product.mediaUrls.length > 0;
  const [hasMediaUrls, setHasMediaUrls] = useState(productHasMediaUrls);
  const productImages = hasMediaUrls ? getImagesForPod(product.mediaUrls) : [product.media.id];
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [activeIndicatorIndex, setActiveIndicatorIndex] = useState(0);

  const { onImageHover } = product.media;
  if (onImageHover === 'FETCH_SECOND_IMAGE' && !isImageGalleryEnabled) {
    if (hasMediaUrls) {
      const onHoverImageIndex = product.mediaUrls.length > 1 ? 1 : 0;
      productImages.push(product.mediaUrls[onHoverImageIndex]);
    } else {
      productImages.push(`${product.media.id}_2`);
    }
  }
  const isMoreThanOneRating = product.totalReviews && product.totalReviews > 0;
  const [finalProductImages, setFinalProductImages] = useState(productImages);
  const [colorIndex, setColorIndex] = useState(getSelectedColorIndex(product));
  useEffect(() => {
    const currentProductUrl = buildPodUrl(product.url, product.skuId);

    if (product.isSponsored) {
      const productUrlWithClickData = getUrlWithSponsoredProductClickData(
        product,
        productIndex,
        config,
        appCtx,
        categoryName
      );

      setProductURL(productUrlWithClickData);
    } else {
      setProductURL(currentProductUrl);
    }
  }, []);

  const shouldShowPlans = () => {
    const COLLECTIONS_TO_SHOW_PLANS = _.get(labels, 'COLLECTIONS_TO_SHOW_PLANS', '').split(',');
    return collectionId && COLLECTIONS_TO_SHOW_PLANS.includes(collectionId);
  };

  const content = () => (
    <LowestPlan
      plan={lowestPlan}
      productId={product.productId}
      isTabApplicable={isTabApplicable}
      layout={layout}
      deviceType={deviceType}
      setLowestPlan={setLowestPlan}
      appCtx={{
        regionCode: appCtx.regionCode,
        priceGroupId: appCtx.priceGroupId,
        politicalId: appCtx.politicalId,
        zones: appCtx.zones,
        tracingHeaders: appCtx.tracingHeaders,
      }}
      shouldShowPlans={shouldShowPlans()}
      labels={{
        NO_PLAN_TITLE: labels.NO_PLAN_TITLE,
        NO_PLAN_LABEL: labels.NO_PLAN_LABEL,
      }}
    />
  );

  const tabData = [
    {
      id: `Prepago-${product.skuId}`,
      value: 'Prepago',
      content: noop,
    },
    {
      id: `Plan-${product.skuId}`,
      value: 'Plan',
      content,
    },
  ];

  const [callToAction, setCallToAction] = useState(getCallToActionText(product, shouldShowPlans()));

  const defaultCheckedState = tabData[shouldShowPlans() ? 1 : 0].id;
  const [checkedState, setCheckedState] = useState(defaultCheckedState);
  const { content: activetabContent } = tabData.find((t) => t.id === checkedState) || tabData[0];

  const handleClick = (setPodClickDdOnXlpOnly = false, isCallFromAddToCart = false) => {
    sponsoredClick('visit', isCallFromAddToCart);
    sponsoredClick('click', isCallFromAddToCart);
    handlePodClick(setPodClickDdOnXlpOnly);
  };

  const handleColorSwatch = (obj, index) => {
    handleClick(true);
    setVariantId(obj.extraInfo);
    const { mediaUrls } = obj;
    const hasMediaUrlsAvailable = mediaUrls.length > 0;
    const images = hasMediaUrlsAvailable ? getImagesForPod(mediaUrls) : [obj.mediaId];
    if (onImageHover === 'FETCH_SECOND_IMAGE' && !isImageGalleryEnabled) {
      if (hasMediaUrlsAvailable) {
        const selectedImageIndex = mediaUrls.length > 1 ? 1 : 0;
        images.push(mediaUrls[selectedImageIndex]);
      } else {
        images.push(`${obj.mediaId}_2`);
      }
    }

    const finalProductUrl = buildPodUrl(obj.url);
    setProductURL(finalProductUrl);
    setHasMediaUrls(hasMediaUrlsAvailable);
    setFinalProductImages(images);
    setColorIndex(index);
    setActiveImageIndex(0);
  };

  const changeCallToAction = (tab, index) => {
    handleClick(true);
    return tab.value === 'Plan' && index === 1 ? setCallToAction('VIEW_PLAN') : setCallToAction('VIEW_PREPAID');
  };

  const handleCheckedState = (tab) => {
    setCheckedState(tab.id);
  };

  const renderTitle = (prod) => {
    return (
      <div>
        <BodyCopy size={styles.title} isHighlighted type="secondary" id={`testId-pod-brandName-${prod.productId}`}>
          {(cn) => <b className={`${cn} pod-title title-rebrand`}>{prod.brand}</b>}
        </BodyCopy>
        <style jsx>{GridPodStyles}</style>
      </div>
    );
  };

  const renderSubTitle = (prod) => {
    return (
      <>
        <BodyCopy maxlines={3} size={styles.subTitle} id={`testId-pod-displayName-${prod.productId}`}>
          {(cn) => (
            <b id={`testId-pod-displaySubTitle-${prod.productId}`} className={`${cn} pod-subTitle subTitle-rebrand`}>
              {prod.displayName}
            </b>
          )}
        </BodyCopy>
        <style jsx>{GridPodStyles}</style>
      </>
    );
  };
  const renderUnitSubtitle = (prod) => {
    if (isTottusSA && prod?.measurements?.format) {
      return (
        <>
          <div className="pod-subtitle-unit">{prod.measurements.format}</div>
          <style jsx>{GridPodStyles}</style>
        </>
      );
    }
    return null;
  };
  const renderSellerText = (prod) => {
    const sellerClass = getSellerIconClass(prod.sellerName || '');
    return (
      <TruncateText>
        <BodyCopy size={styles.subTitle} id={`testId-pod-sellerName-${prod.productId}`}>
          {(cn) => (
            <b
              id={`testId-pod-displaySellerText-${prod.productId}`}
              className={`${cn} pod-sellerText seller-text-rebrand`}
            >
              {prod.sellerName ? `Por ${capitalizeText(prod.sellerName)}` : ''}
            </b>
          )}
        </BodyCopy>
        {sellerClass && <span id="seller-icon" className={`${sellerClass}Icon seller-icon`} />}
        <style jsx>{GridPodStyles}</style>
      </TruncateText>
    );
  };
  const closeClick = () => {
    setCloseToolTip(true);
    setShowOverlay(false);
    toggleBodyClass(false, 'no-scroll');
    WebStorage.setItem({
      name: 'showImgGalleryMsg',
      value: 'false',
      shouldPersist: true,
    });
  };
  const finalProductUrl = buildPodUrl(product.url, variantId);
  return (
    <a
      href={`${formatUrl(callToAction, productUrl)}`}
      className={`pod pod-${layout} pod-link`}
      data-pod="catalyst-pod"
      data-key={product.productId}
      data-category={product.merchantCategoryId}
      data-gsccategory={product.GSCCategoryId}
      data-sponsored={product.isSponsored}
      id={`testId-pod-${variantId}`}
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
      role="button"
      tabIndex={0}
      onClick={() => handleClick()}
      ref={podRef}
    >
      <div
        className={`pod-head${
          isImageGalleryMsgEnabled && isFirstPod && deviceType !== 'desktop' && !closeToolTip
            ? ' overlay-exception'
            : ''
        }`}
      >
        {isImageGalleryMsgEnabled && isFirstPod && deviceType !== 'desktop' && !closeToolTip && (
          <div className="tooltip-wrapper">
            <Tooltip
              position="middle-1"
              hasCaret
              showWithoutHover
              tooltipContent={
                <TooltipText text="Ya puedes realizar swipe para ver todas las imagenes" viewType="x-small" />
              }
              onCloseClick={closeClick}
              showCrossButton
            />
          </div>
        )}
        {product.isBestSeller && <BestSellerRibbon />}
        {isImageGalleryEnabled ? (
          <ImageGallery
            activeImageIndex={activeImageIndex}
            images={finalProductImages}
            activeIndicatorIndex={activeIndicatorIndex}
            setActiveIndicatorIndex={setActiveIndicatorIndex}
            setActiveImageIndex={setActiveImageIndex}
            showControlButtons
            lazyLoad={lazyLoadImage}
            className={`layout_${styles.imageGroup} layout_view_${layout}`}
            width={styles.imageDimension}
            height={styles.imageDimension}
            alt={`${product.brand} - ${product.displayName}`}
            isFirstPod={isFirstPod}
            hideLazyLoadPlaceHolder
          />
        ) : (
          <ImageGroup
            ids={finalProductImages}
            lazyLoad={lazyLoadImage}
            mouseOver={hoverState}
            isBestSeller={product.isBestSeller}
            width={styles.imageDimension}
            height={styles.imageDimension}
            className={`layout_${styles.imageGroup} layout_view_${layout}`}
            alt={`${product.brand} - ${product.displayName}`}
            hideLazyLoadPlaceHolder
            hasMediaUrls={hasMediaUrls}
            isFirstPod={isFirstPod}
          />
        )}
        <div className={`badges-wrapper badges-wrapper-${layout}`}>
          <Badges
            badgeItems={hasStickers ? meatStickers : badges}
            deviceType={deviceType}
            layout={layout}
            isSticker={hasStickers}
          />
        </div>
      </div>
      <div className={`pod-details pod-details-${layout}${hasStickers ? ' has-stickers' : ''}`}>
        <div>
          {renderTitle(product)}
          {renderSubTitle(product)}
          {renderUnitSubtitle(product)}
          {renderSellerText(product)}
        </div>
        <div className="my-list-xlp">
          <MyLists
            className="xlp-container"
            appCtx={appCtx}
            product={{
              productId: product.productId,
              variantId: product.skuId,
              offeringId: product.offeringId,
            }}
            handleClick={handleClick}
          />
        </div>
        {product.variants.map((variant) => {
          return (
            isColorVariant(variant) && (
              <ColorSwatch
                colorVariants={variant.options}
                handleColorSwatch={handleColorSwatch}
                selectedColorIndex={colorIndex}
                productDisplayLink={finalProductUrl}
                productId={product.productId}
                key={product.productId}
                maximum={5}
                size={styles.colorSwatch}
                layout={layout}
              />
            )
          );
        })}
      </div>
      {isTabApplicable && (
        <Tab
          tabData={tabData}
          changeCallToAction={changeCallToAction}
          handleCheckedState={handleCheckedState}
          checkedState={checkedState}
          productId={product.productId}
          skuId={product.skuId}
          productType={product.viewTemplate}
        />
      )}
      <div className={`pod-summary pod-summary-${layout}${isTottusSA ? ' tottus' : ''}`}>
        {callToAction === 'VIEW_PLAN' ? (
          <div className="tabContent">
            <BodyCopy size="copy5">
              <div className="tabContent-text">{activetabContent()}</div>
            </BodyCopy>
          </div>
        ) : (
          <Prices
            priceList={product.prices}
            productId={product.productId}
            isTabApplicable={isTabApplicable}
            page="SLP"
            layout={layout}
            variant={`${deviceType === 'desktop' ? 'DESKTOP' : 'MOBILE'}_${layout}`}
            discountBadge={product.discountBadge}
            product={product}
          />
        )}
        {isMultipurposeBadgeApplicable && multipurposeBadges.length > 0 ? (
          <div className="multipurpose-badge">
            <MultipurposeBadge badgeItems={multipurposeBadges} deviceType={deviceType} layout={layout} isMultiPurpose />
          </div>
        ) : (
          <div className="empty-mkp-badge-space" />
        )}
        <div className={`pod-meta meta-${layout}`}>
          <ShippingDetails
            availability={product.availability}
            labels={labels}
            deviceType={deviceType}
            layout={layout}
          />

          {isMoreThanOneRating && (
            <div
              className={`pod-rating ${isMoreThanOneRating ? 'rating-rebranding' : ''} pod-rating-${layout} ${
                isMoreThanOneRating ? '' : 'no-rating'
              }`}
            >
              <RatingsVisual value={Number(product.rating)} productId={product.productId} size={styles.ratingVisual} />
              <Reviews value={Number(product.totalReviews)} layout={layout} />
            </div>
          )}
          {isTottusSA && (
            <AddToCartButton
              addToCart={clickForProductDetails}
              colorIndex={colorIndex}
              isAddToCartInProgress={isAddToCartInProgress}
              product={product}
              setIsAddToCartInProgress={setIsAddToCartInProgress}
            />
          )}
          <div className="patrocinado-pod">
            {isSponsored && <div className="patrocinado-title">{sponsoredText}</div>}
          </div>
          {!isTottusSA && (
            <div
              className={`${
                isActionPlaceHolderRequired(deviceType)
                  ? `action-button-place-holder action-button-place-holder-${layout}`
                  : ''
              } action-button-rebranding`}
            >
              {isPodActionButtonEnabled && (
                <div
                  className={`pod-action pod-action-${layout} ${
                    constants.LAYOUT_1_GRID !== layout ? 'rebranding-pod-action' : ''
                  }`}
                >
                  {!(callToAction === 'VIEW_PLAN' && !lowestPlan) && (
                    <Button
                      id={`testId-Pod-action-${product.productId}`}
                      type="mkp-primary-medium primary-theme"
                      disabled={
                        !product.prices || product.prices.length === 0 || (isAddToCartInProgress && !isSelected)
                      }
                      onClick={(event) => {
                        if (!isTabApplicable) {
                          event.stopPropagation();
                          handleClick(true, true);
                        } else {
                          handleClick(false, true);
                        }
                        if (isAddToCartInProgress) {
                          return event.preventDefault();
                        }
                        if (!(isTabApplicable || redirectToPDP)) {
                          event.preventDefault();
                        }
                        return isTabApplicable || redirectToPDP
                          ? redirect(formatUrl(callToAction, productUrl))
                          : clickForProductDetails(colorIndex);
                      }}
                    >
                      <div className="pod-button-add-to-cart">
                        {isAddToCartInProgress && isSelected && (
                          <div className="pod-button-add-to-cart-spinner">
                            <MarketplaceSpinner size="small" color="primary" />
                          </div>
                        )}
                        <div className="btn-text">
                          {callToAction === 'VIEW_PLAN' ? labels.VIEW_PLAN_REBRANDING : buttonLabel}
                        </div>
                      </div>
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <style jsx>{GridPodStyles}</style>
      <style jsx>{GridPodGlobalStyles}</style>
    </a>
  );
};

GridPod.defaultProps = {
  product: {},
  lazyLoadImage: false,
  layout: '4_GRID',
  appCtx: {},
  config: {},
  isPodActionButtonEnabled: false,
  collectionId: '',
  redirectToPDP: false,
  setShowOverlay: noop,
};

GridPod.propTypes = {
  product: PropTypes.object,
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object,
  config: PropTypes.object,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  lazyLoadImage: PropTypes.bool,
  layout: PropTypes.string,
  clickForProductDetails: PropTypes.func.isRequired,
  isAddToCartInProgress: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isPodActionButtonEnabled: PropTypes.bool,
  sponsoredClick: PropTypes.func.isRequired,
  collectionId: PropTypes.string,
  handlePodClick: PropTypes.func.isRequired,
  isFirstPod: PropTypes.bool.isRequired,
  redirectToPDP: PropTypes.bool,
  setIsAddToCartInProgress: PropTypes.object.isRequired,
  index: PropTypes.object.isRequired,
  setShowOverlay: PropTypes.func,
};

export { GridPod };
export default withLabels(withConfig(GridPod));
