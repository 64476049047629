const getCountry = () => {
  const pathName = window.location.pathname;
  const pathArray = pathName.split('/');
  const tenant = pathArray.length > 1 ? pathArray[1] : 'falabella-cl';
  const countryArray = tenant.split('-');
  const country = countryArray.length > 1 ? countryArray[1] : 'cl';
  return country.toUpperCase();
};

export { getCountry };
