import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import noop from '../../../utils/noop';
import { withProduct } from '../../../utils/ProductContext';
import ImageListing from '../../ui/ImageListing/ImageListing';
import ImageGalleryControlButtons from '../ImageGalleryControlButtons/ImageGalleryControlButtons';
import { IMAGE_GALLERY, getMediaData } from '../ImageGalleryHelper';
import SliderDots from '../SliderDots/SliderDots';
import { ImageGalleryDesktopStyles, PreloadImageStyles } from './ImageGalleryDesktop.style';

const ImageGalleryDesktop = ({
  activeImageIndex,
  images,
  activeIndicatorIndex,
  setActiveIndicatorIndex,
  setActiveImageIndex,
  lazyLoad,
  className,
  width,
  height,
  alt,
  isFirstPod,
  hideLazyLoadPlaceHolder,
  appCtx,
}) => {
  const maxImagesForGallery = _.get(appCtx, 'siteConfig.toggles.maxImagesForGallery', 5);
  const isImageGallerySilderEnabled = _.get(appCtx, 'siteConfig.toggles.isImageGallerySilderEnabled', false);
  const galleryImages = images.slice(0, maxImagesForGallery);
  const [mouseOver, setMouseOver] = useState(false);
  const [preloadImagesDone, setPreloadImagesDone] = useState(false);
  const handleMouseLeave = () => {
    setMouseOver(false);
    setActiveIndicatorIndex(0);
    setActiveImageIndex(0);
  };
  const onNextHeadline = () => {
    const isLastImage = activeImageIndex + 1 === galleryImages.length;
    if (!isLastImage) {
      setActiveIndicatorIndex(activeIndicatorIndex + 1);
      setActiveImageIndex(activeImageIndex + 1);
    }
  };

  const onPreviousHeadLine = () => {
    const isFirstImage = activeImageIndex === 0;

    if (!isFirstImage) {
      setActiveIndicatorIndex(activeIndicatorIndex - 1);
      setActiveImageIndex(activeImageIndex - 1);
    }
  };
  const preloadImages = () => {
    return (
      <>
        {galleryImages.map((img, index) => {
          const { mediaId, mediaUrl } = getMediaData(img);
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className="hidden" key={`${mediaId}-desktop-${index}`}>
              <ImageListing
                id={mediaId}
                width={width}
                height={height}
                alt={alt}
                imageUrl={mediaUrl}
                hideLazyLoadPlaceHolder={hideLazyLoadPlaceHolder}
              />
            </div>
          );
        })}
        <style jsx>{PreloadImageStyles}</style>
      </>
    );
  };

  const activeImage = galleryImages[activeImageIndex] || IMAGE_GALLERY.DEFAULT_IMAGE;
  const { mediaId: activeImageId, mediaUrl: activeImageUrl } = getMediaData(activeImage);
  const handleMouseOver = () => {
    setMouseOver(true);
    setPreloadImagesDone(true);
  };
  return (
    <div onMouseMove={handleMouseOver} onMouseLeave={handleMouseLeave} onFocus={noop}>
      {mouseOver && preloadImagesDone && preloadImages()}
      {mouseOver && galleryImages.length - 1 > 0 && (
        <ImageGalleryControlButtons onNextHeadline={onNextHeadline} onPreviousHeadLine={onPreviousHeadLine} />
      )}
      {
        <section className={`${mouseOver ? 'image--active' : ''} ${className}`}>
          <ImageListing
            id={activeImageId}
            width={width}
            height={height}
            alt={alt}
            lazyLoad={lazyLoad}
            imageUrl={activeImageUrl}
            priorityFetch={!!isFirstPod}
          />
        </section>
      }
      {isImageGallerySilderEnabled && mouseOver && galleryImages.length - 1 > 0 && (
        <SliderDots
          allDots={[...Array(galleryImages.length).keys()]}
          maxDots={maxImagesForGallery}
          selectedIndex={activeImageIndex}
          onDotClick={(newIndex) => setActiveImageIndex(newIndex + 1)}
        />
      )}
      <style jsx>{ImageGalleryDesktopStyles}</style>
    </div>
  );
};

ImageGalleryDesktop.defaultProps = {
  images: [],
  lazyLoad: true,
  className: 'grid-view',
  width: 249,
  height: 249,
  alt: 'preview',
  hideLazyLoadPlaceHolder: false,
  appCtx: {},
};

ImageGalleryDesktop.propTypes = {
  activeImageIndex: PropTypes.number.isRequired,
  images: PropTypes.array,
  activeIndicatorIndex: PropTypes.number.isRequired,
  setActiveIndicatorIndex: PropTypes.func.isRequired,
  setActiveImageIndex: PropTypes.func.isRequired,
  lazyLoad: PropTypes.bool,
  className: PropTypes.oneOf(['grid-view', 'list-view', 'layout_1_GRID', 'layout_2_GRID']),
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string,
  isFirstPod: PropTypes.bool.isRequired,
  hideLazyLoadPlaceHolder: PropTypes.bool,
  appCtx: PropTypes.object,
};

export default withApplicationContext(withProduct(ImageGalleryDesktop));
export { ImageGalleryDesktop };
