import PropTypes from 'prop-types';
import React from 'react';
import MatchMedia from '../ui/MatchMedia/MatchMedia';
import CartDesktop from './CartDesktop/CartDesktop';
import CartMobile from './CartMobile/CartMobile';

const CartSection = ({ setBusy, cart, hasCrossSelling, setCrossSelling }) => {
  const desktop = () => (
    <CartDesktop cart={cart} setBusy={setBusy} hasCrossSelling={hasCrossSelling} setCrossSelling={setCrossSelling} />
  );
  const noDesktop = () => <CartMobile cart={cart} setBusy={setBusy} />;

  return (
    <MatchMedia
      content={{
        desktop,
        mobile: noDesktop,
        tablet: noDesktop,
      }}
    />
  );
};

CartSection.propTypes = {
  setBusy: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
  hasCrossSelling: PropTypes.bool.isRequired,
  setCrossSelling: PropTypes.func.isRequired,
};

export { CartSection };
