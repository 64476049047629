import { useEffect, useState } from 'react';

const useIntersection = (element, rootMargin, threshold, multipleObserve = false) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(entry.isIntersecting);
          if (!multipleObserve) {
            observer.unobserve(element.current);
          }
        }
      },
      { rootMargin, threshold }
    );
    if (element && element.current) {
      observer.observe(element.current);
    }
    return () => observer.unobserve(element.current);
  }, []);

  return isVisible;
};

export default useIntersection;

// 1. If element is being observed if element.current is valid
// 2. If isVisible becomes true, if element comes in viewport
// 3. If multipleObserve is false, check if element is unobserved
// 4.
