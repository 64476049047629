const getUniqueColorVariantsSLP = (currentVariant, variants = []) => {
  const mapper = {};
  return variants
    .map((variant) => {
      const { label, value, ...rest } = variant;
      if (!value || !label) return undefined;
      if (!variant.isPurchaseable) {
        return undefined;
      }
      if (mapper[label]) return undefined;
      mapper[label] = label;
      return {
        extraInfo: variant.id,
        label,
        mediaId: variant.id,
        selected: variant.id === currentVariant.id,
        value,
        ...rest,
      };
    })
    .filter(Boolean);
};

export { getUniqueColorVariantsSLP };
