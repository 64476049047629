import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

const alertIconSvgMkp = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.33332 4.16668H10.6667V12.5H9.33332V4.16668Z" fill="#F7B500"/>
<path d="M11 14.9999C11 15.5522 10.5523 15.9999 10 15.9999C9.44774 15.9999 9.00002 15.5522 9.00002 14.9999C9.00002 14.4476 9.44774 13.9999 10 13.9999C10.5523 13.9999 11 14.4476 11 14.9999Z" fill="#F7B500"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 4.5 4.5 0 10 0C15.5 0 20 4.5 20 10C20 15.5 15.5 20 10 20C4.5 20 0 15.5 0 10ZM18.6667 10C18.6667 5.1667 14.8334 1.33337 10 1.33337C5.1667 1.33337 1.33337 5.1667 1.33337 10C1.33337 14.8334 5.1667 18.6667 10 18.6667C14.8334 18.6667 18.6667 14.8334 18.6667 10Z" fill="#F7B500"/>
</svg>`;

const AddToCartErrorStyles = css`
  .popup {
    height: calc(100vh - 120px);
    cursor: initial;
    background-color: ${colors.white.shade1};
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    @mixin tabletToDesktop {
      height: auto;
      width: auto;
      min-height: 251px;
    }
  }

  .header-and-body {
    padding: 21px 24px 21px;
    width: inherit;
    height: auto;
    border-radius: 4px 4px 0 0;

    .header {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid ${colors.gray.shade50};

      .csicon-icon-success-circle {
        color: ${colors.green.shade1};
        font-size: ${typography.xl3a};
      }

      .label {
        flex-grow: 1;
        margin: 0 14px;
        line-height: 22px;
      }

      .csicon-danger {
        color: ${colors.gray.shade29};
        font-size: ${typography.sm};
      }

      .warning-icon {
        width: 20px;
        height: 20px;
        background-image: url('${svgToDataUri(alertIconSvgMkp)}');
        background-size: contain;
        background-repeat: no-repeat;
        @mixin tabletToDesktop {
          width: 20px;
        }
      }
    }

    .body {
      padding: 24px 0;
      overflow-x: hidden;
      flex-grow: 1;

      @mixin tabletToDesktop {
        height: auto;
      }

      .collections,
      .bundles {
        border-top: 1px solid ${colors.gray.shade14};

        span {
          margin: 22px 0;
          display: block;
        }
      }

      .collections {
        padding-top: 22px;
      }
    }
    .login-again-btn {
      border-bottom: 1px solid ${colors.gray.shade40};
      margin-left: 5px;
    }
  }

  .product-details {
    display: flex;
    border-bottom: 1px solid ${colors.gray.shade14};
    padding: 18px 0;

    .product-image {
      box-sizing: border-box;
      height: 89px;
      width: 89px;
      border: 1px solid ${colors.gray.shade43};
      @mixin tabletToDesktop {
        height: 46px;
        width: 46px;
      }
    }
    .item-info {
      margin: 0 18px;
    }
    .info-wrapper {
      display: flex;
      flex-direction: column;
      @mixin tabletToDesktop {
        flex-direction: row;
      }
    }
  }

  .price {
    margin: 21px 18px 0;
    @mixin tabletToDesktop {
      margin: 0;
    }
  }

  .variant-details {
    display: flex;
    padding: 15px;
    flex-direction: column;
    width: 100%;
    height: auto;

    @mixin tabletToDesktop {
      flex-direction: row;
      justify-content: flex-end;
      border-top: 1px solid ${colors.gray.shade14};
      background-color: ${colors.gray.shade41};
      border-radius: 0 0 4px 4px;
      padding: 20px 24px 25px 0;
    }

    &.mkp {
      @mixin tabletToDesktop {
        flex-direction: row;
        justify-content: flex-end;
        border-top: 1px solid ${colors.gray.shade14};
        background-color: ${colors.gray.shade41};
        border-radius: 0 0 4px 4px;
        padding: 20px 33px 25px 0;
      }
    }

    .color-variants {
      width: 100%;
      margin-right: 28px;
      @mixin tabletToDesktop {
        width: 120px;
      }
    }
    .size-variants {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0;
      @mixin tabletToDesktop {
        width: 270px;
        margin: 0 10px 0 28px;
      }
      .sizes-group {
        margin-top: 10px;
      }
      .size-title {
        font-weight: bold;
      }
    }
    .addToCart-container {
      width: 195px;
      background-color: ${colors.gray.shade41};

      @mixin mobileToTablet {
        position: absolute;
        padding: 25px;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
        border-top: 1px solid ${colors.gray.shade14};
        border-radius: 0 0 4px 4px;
      }

      .cerrar-button {
        margin-right: 20px;
        font-size: ${typography.base};
        .cerrar-button-text {
          letter-spacing: 0.19px;
          line-height: 16px;
          padding-bottom: 1px;
          border-bottom: 0.5px solid ${colors.black.shade9};
        }
        @mixin mobileToTablet {
          margin-top: 20px;
        }
      }
    }

    .addToCart-container-mkp {
      width: auto;
      background-color: ${colors.gray.shade41};

      @mixin mobileToTablet {
        position: absolute;
        padding: 25px;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
        border-top: 1px solid ${colors.gray.shade14};
        border-radius: 0 0 4px 4px;
      }

      .cerrar-button {
        margin-right: 20px;
        font-size: ${typography.base};
        .cerrar-button-text {
          letter-spacing: 0.19px;
          line-height: 16px;
          padding-bottom: 1px;
          border-bottom: 0.5px solid ${colors.black.shade9};
        }
        @mixin mobileToTablet {
          margin-top: 20px;
        }
      }
    }

    .add-to-cart-error {
      button {
        background-color: ${colors.black.shade9};
      }
    }
  }
`;
export { AddToCartErrorStyles };
