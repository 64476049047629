import css from 'styled-jsx/css';
import { fontUrl } from '../../utils/staticUrls';

export const layoutStyle = () => {
  return css.global`
    @import 'static/styles/base.css';
    @import 'static/icons/style.css';
    @import '@digital-retail/falabella-ui-cross-components/dist/cross.component.styles.css';
    @font-face {
      font-family: Lato;
      src: url(${fontUrl('Lato-Black.ttf')});
      font-weight: 900;
      font-display: fallback;
    }
    @font-face {
      font-family: Lato;
      src: url(${fontUrl('Lato-BlackItalic.ttf')});
      font-weight: 900;
      font-style: italic;
      font-display: fallback;
    }
    @font-face {
      font-family: Lato;
      src: url(${fontUrl('Lato-Bold.ttf')});
      font-weight: 700;
      font-display: fallback;
    }
    @font-face {
      font-family: Lato;
      src: url(${fontUrl('Lato-BoldItalic.ttf')});
      font-weight: 700;
      font-style: italic;
      font-display: fallback;
    }
    @font-face {
      font-family: Lato;
      src: url(${fontUrl('Lato-Hairline.ttf')});
      font-weight: 100;
      font-display: fallback;
    }
    @font-face {
      font-family: Lato;
      src: url(${fontUrl('Lato-HairlineItalic.ttf')});
      font-weight: 100;
      font-style: italic;
      font-display: fallback;
    }
    @font-face {
      font-family: Lato;
      src: url(${fontUrl('Lato-Italic.ttf')});
      font-style: italic;
      font-display: fallback;
    }
    @font-face {
      font-family: Lato;
      src: url(${fontUrl('Lato-Light.ttf')});
      font-weight: 300;
      font-display: fallback;
    }
    @font-face {
      font-family: Lato;
      src: url(${fontUrl('Lato-LightItalic.ttf')});
      font-weight: 300;
      font-style: italic;
      font-display: fallback;
    }
    @font-face {
      font-family: Lato;
      src: url(${fontUrl('Lato-Regular.ttf')});
      font-weight: 400;
      font-display: fallback;
    }
    @font-face {
      font-family: AvenirNextCondensed;
      src: url(${fontUrl('AvenirNextCondensed-Bold.ttf')});
      font-weight: 800;
      font-display: fallback;
    }

    input::-ms-reveal,
    input::-ms-clear {
      display: none;
    }
    :global(.doc-click-overlay) {
      position: relative;
      display: flex;
      height: 100%;
      align-items: flex-end;
      justify-content: center;
      cursor: pointer;
    }

    div#google-banner-superior-div {
      margin-bottom: 0 !important;
    }
  `;
};
