import PropTypes from 'prop-types';
import React from 'react';
import apiConfig from '../../../config/api/apiConfig';
import constants from '../../../config/constants';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withLabels } from '../../../utils/LabelsContext';
import _ from '../../../utils/LodashImports';
import noop from '../../../utils/noop';
import { withProduct } from '../../../utils/ProductContext';
import BodyCopy from '../../ui/BodyCopy/BodyCopy';
import Button from '../../ui/Button/Button';
import LinkButton from '../../ui/LinkButton/LinkButton';
import { renderBundleItem, renderCollections, showWarningMessage } from '../Cart.Utils';
import CartItem from '../CartItem/CartItem';
import { CartAddedBundleStyles } from './CartBundleDesktop.style';
import { CartBrandNameStyle, CartDescriptionStyle, CartDesktopStyles } from './CartDesktop.style';

const CartDesktop = ({ cart, setBusy, labels, product, appCtx, hasCrossSelling, setCrossSelling }) => {
  const { bundles, attributes, disableQuantitySelection } = product;
  const { viewTemplate } = attributes;

  const { regionCode } = appCtx;

  const {
    cartItems,
    bundleItems,
    textDictionary,
    addProduct,
    removeProduct,
    setWarrantyOption,
    setAssemblyOption,
    bundledProductOrigin,
    typeACollections = [],
    addCollection,
    endPoints,
    removeCollection,
    setWarrantyOptionCurrent,
    limitAlerts,
  } = cart;
  if (!cartItems) {
    return null;
  }
  const { A2C_CONTINUE_SHOPPING = '', A2C_VIEW_BASKET_MKP = 'Ir al Carro' } = _.get(
    appCtx,
    'siteConfig.textDictionary',
    {}
  );

  const { variants, currentVariant, id: productId, brandName, stockUnits = {}, isSLP = false } = product;

  const guideSymbol = _.get(product, 'guideSymbol', false);
  const isSoftlineProduct = product.layoutType === constants.PRODUCT_CONSTANTS.SOFTLINE;

  const {
    colorCode,
    size = '',
    colorName = '',
  } = isSLP
    ? currentVariant
    : _.get(
        variants.find(({ id }) => id === currentVariant),
        'attributes',
        {}
      );

  const getVariantDetails = () => {
    if (colorName && size) {
      return `${colorName},${size}`;
    }
    return `${colorName} ${size}`;
  };

  const variantDetails = isSoftlineProduct || guideSymbol ? getVariantDetails() : '';

  const imageBaseURL = apiConfig.getImagesURL(regionCode);

  const renderBrandName = (brand) => (
    <BodyCopy size="copy6" type="secondary" isHighlighted>
      <span className="brand-name">{`${brand}`.toUpperCase()}</span>
      <style jsx>{CartBrandNameStyle}</style>
    </BodyCopy>
  );

  const renderDescription = (displayName) => (
    <BodyCopy size="copy5">
      <span className="description">{displayName}</span>
      <style jsx>{CartDescriptionStyle}</style>
    </BodyCopy>
  );

  const isTypeA = viewTemplate === constants.VIEW_TEMPLATE.TYPE_A;

  const isBundlesAvailable = bundles && bundles.length !== 0;

  const closeModal = () => {
    setBusy(false);
    cart.setShowCart(false);
  };
  const hasWarranties = cartItems.filter((item) => item.warrantyOptions);
  const hasServices = cartItems.filter((item) => item.assemblyOptions);

  let bodyClass = hasWarranties.length > 0 || product.warrantyOptions ? 'body-medium' : '';
  bodyClass = hasWarranties.length > 0 && hasServices.length > 0 ? 'body-large' : bodyClass;

  return (
    <div className={`popup ${!hasCrossSelling && 'small'}`}>
      <div className="header mkp">
        <i className="csicon-icon-success-circle">
          <span className="sr-only">{labels.ITEM_ADDED_A11Y_LABEL}</span>
        </i>

        <span className="label">
          <BodyCopy size="copy10">{labels.ITEM_ADDED_IN_CART}</BodyCopy>
        </span>

        <button type="button" onClick={closeModal}>
          <i className="csicon-danger">
            <span className="sr-only">{labels.CLOSE_A11Y_LABEL}</span>
          </i>
        </button>
      </div>
      <div
        className={`body ${!hasCrossSelling && 'small'} ${
          showWarningMessage(stockUnits, cart) && 'with-warning'
        } ${bodyClass}`}
      >
        <div className="cart-items">
          {cartItems &&
            cartItems.map((item) => (
              <CartItem
                key={item.skuId}
                setBusy={setBusy}
                item={item}
                endPoints={endPoints}
                onRemoveFromCart={removeProduct}
                setWarrantyOption={setWarrantyOption}
                setAssemblyOption={setAssemblyOption}
                onAddToCart={addProduct}
                showButton={false}
                setCrossSelling={setCrossSelling}
                variantDetails={variantDetails}
                disableQuantitySelection={disableQuantitySelection}
                setWarrantyOptionCurrent={setWarrantyOptionCurrent}
                limitAlerts={limitAlerts}
              />
            ))}
        </div>
        {isBundlesAvailable && (
          <div className="bundles">
            {renderBundleItem({
              bundledProductOrigin,
              bundles,
              product,
              labels,
              imageBaseURL,
              CartAddedBundleStyles,
              bundleItems,
              setBusy,
              textDictionary,
              removeProduct,
              setWarrantyOption,
              setWarrantyOptionCurrent,
              addProduct,
              renderDescription,
              renderBrandName,
              isTypeA,
              limitAlerts,
            })}
          </div>
        )}
        {isTypeA && (
          <div className="collections">
            {renderCollections({
              labels,
              variants,
              colorCode,
              size,
              brandName,
              imageBaseURL,
              productId,
              setBusy,
              textDictionary,
              isBundlesAvailable,
              removeProduct: (props) => removeCollection({ ...props }),
              addProduct: (props) => addCollection({ ...props }),
              cartItems,
              collection: typeACollections,
              limitAlerts,
            })}
          </div>
        )}
      </div>
      <div className="footer">
        {showWarningMessage(stockUnits, cart) && (
          <div className="warning">
            <i className="csicon-alert">
              <span className="sr-only">{labels.WARNING_A11Y_LABEL}</span>
            </i>
            <span>{labels.LOW_STOCK_WARNING}</span>
          </div>
        )}
        <div className="sub-footer">
          <Button type="secondary-link-mkp" onClick={closeModal}>
            {A2C_CONTINUE_SHOPPING}
          </Button>
          <div className="addToCart-btn mkp">
            <LinkButton href={_.get(endPoints, 'baskePagetUri.path', '/')} type="mkp-secondary">
              {A2C_VIEW_BASKET_MKP}
            </LinkButton>
          </div>
        </div>
      </div>

      <style jsx>{CartDesktopStyles}</style>
    </div>
  );
};

CartDesktop.defaultProps = {
  setBusy: noop,
  hasCrossSelling: false,
  setCrossSelling: noop,
};

CartDesktop.propTypes = {
  cart: PropTypes.object.isRequired,
  setBusy: PropTypes.func,
  labels: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  hasCrossSelling: PropTypes.bool,
  setCrossSelling: PropTypes.func,
};

export default withApplicationContext(withLabels(withProduct(CartDesktop)));
export { CartDesktop };
