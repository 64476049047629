import css from 'styled-jsx/css';
import { colors, icons, typography, zIndex } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

const facetsMobileStyles = css`
  .facetsMobile {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid ${colors.gray.shade17};
    min-height: 41px;
    .facetmobile--items {
      flex: 1 0 auto;
      .count {
        background: ${colors.black.shade1};
        border-radius: 50px;
        color: ${colors.white.shade1};
        width: 20px;
        display: inline-block;
        height: 20px;
        text-align: center;
        padding: 3px 0;
        margin-right: 5px;
        font-size: 11px;
      }
      .mkp-count {
        background: ${colors.blue.shade3};
        border-radius: 15px;
        color: ${colors.white.shade1};
        width: 20px;
        height: 20px;
        text-align: center;
        padding: 2px 0;
        margin-right: 5px;
        font-size: 1.2rem;
      }
    }
    button {
      width: 100%;
    }
    .facetmobile-container {
      background: ${colors.white.shade1};
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .facetmobile-filters {
      display: flex;
    }
  }
  .btn-with-icon {
    font-size: ${typography.lg1};
    justify-content: center;
    height: 40px;
    padding: 0 10px;
    border-right: 1px ${colors.gray.shade14} solid;
    align-items: center;
    color: #000;
    display: flex;
    i {
      margin-right: 9px;
      width: 20px;
      font-size: 18px;
    }
    .csicon-disponibilidad-mkp {
      width: 29.4px;
      height: 18.8px;
      background-image: url('${svgToDataUri(icons.shippingIcon)}');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
`;

const facetsMobileGlobalStyle = css.global`
  body.FacetMobilePanel--open {
    overflow: hidden;
  }
  .facetmobile--items span {
    font-size: ${typography.base};
    font-weight: 700;
  }
  .mkp {
    letter-spacing: -0.29px;
  }

  .stickyHeader {
    .facetmobile-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: ${zIndex.facets.header};
      box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
      transition: all 225ms ease-out;
    }

    &.footerVisible {
      .facetmobile-container {
        display: none !important;
      }
    }
  }
`;

const actionsStyles = css`
  .actions {
    border-top: 1px solid #f0f0f0;
    position: fixed;
    display: flex;
    flex-direction: column;
    bottom: 0;
    width: 84%;
    height: 75px;
    justify-content: center;
    align-items: center;
    background-color: ${colors.white.shade1};
    z-index: ${zIndex.dialog.footer};
  }
  /* stylelint-disable font-family-no-missing-generic-family-keyword, length-zero-no-unit */
  .actions-btn {
    padding: 0px 12.5px;
    position: relative;
    &.link {
      padding-bottom: 5px;
    }
  }
  .actions-mkp-primary {
    max-width: 156px;
    min-height: 45px;
    line-height: 2.3rem;
  }
  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
  }
  .total-results {
    display: flex;
    color: var(--grayscale-black-base-800, #333);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    width: 50%;
    padding-left: 10px;
    white-space: nowrap;
  }
`;

export { actionsStyles, facetsMobileGlobalStyle, facetsMobileStyles };
