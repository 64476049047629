import {
  getUrlForBrand,
  getUrlForCategory,
  getUrlForCollection,
  getUrlForSearch,
  getUrlForSeller,
} from '@digital-retail/store-manager';
import Router from 'next/router';
import constants from '../config/constants';
import { isWindow } from './deviceChecker';
import noop from './noop';

const serializeQuery = (params = {}, ignoreQuery = []) => {
  return Object.keys(params)
    .filter((name) => ignoreQuery.indexOf(name) === -1)
    .map((name) => {
      const value = encodeURIComponent(params[name]);
      return `${encodeURIComponent(name)}=${value}`;
    })
    .join('&');
};

const parseQueryString = (url) => {
  const params = {};
  if (url.indexOf('?') === -1) {
    return params;
  }
  url
    .substring(url.indexOf('?') + 1)
    .split('&')
    .filter((param) => param)
    .filter((p) => !!p)
    .forEach((param) => {
      const [name, value] = param.split('=');
      params[name] = value || '';
    });

  return params;
};

const navigate = ({ url, asUrl, params, site, clearParams, extraQuery = '', onRouteChange = noop }) => {
  const oldParams = parseQueryString(Router.router.asPath);

  const newParams = clearParams
    ? params
    : {
        ...oldParams,
        ...params,
      };
  const paramString = serializeQuery(newParams || {});
  const href = `${url}?site=${site}${paramString && `&${paramString}`}${extraQuery}`;
  const asHref = `/${site}${asUrl || url}?${paramString}`;
  onRouteChange();
  Router.push(href, asHref);
};

const navigateWithUrl = ({ regionCode, site, url, onRouteChange = noop, store = null }) => {
  const href = getUrlForSearch({ store, regionCode, environment: 'production' });
  const urlSearchParams = new URLSearchParams(url || '');

  urlSearchParams.forEach((value, key) => {
    href.searchParams.set(key, value);
  });

  const asHref = new URL(href);

  href.searchParams.set('site', site);
  if (store) {
    href.searchParams.set('store', store);
  }

  onRouteChange();

  Router.push(`/${href.pathname.split('/')[2]}?${href.searchParams}`, `${asHref.pathname}?${asHref.searchParams}`);
};

const redirect = (url, doReplace = false) => {
  if (doReplace) {
    window.location.replace(url);
  } else {
    window.location.href = url;
  }
};

const reloadWithParams = ({ onRouteChange = noop, ignoreQuery = [], newParams = '', tenant = 'falabella-cl' }) => {
  const paramString = serializeQuery(Router.router.query, ignoreQuery);
  const href = `${Router.router.pathname}?${paramString}${newParams}`;

  let asHref = Router.router.asPath;
  if (Router.router.pathname === '/noResult') {
    const { query } = Router.router;
    const termo = query.Ntt;
    if (termo) {
      redirect(`/${tenant}/search?Ntt=${termo}`);
    }
    return;
  }

  asHref = asHref
    .split('?')
    .map((item) => {
      return item
        .split('&')
        .filter((param) => ignoreQuery.indexOf(param.split('=')[0]) === -1)
        .join('&');
    })
    .filter((item) => item)
    .join('?');

  onRouteChange();
  if (Router.router) Router.push(href, asHref);
  // else if (Router.router) Router.replace(href,asHref);
};

const changeProductRoute = ({ id, slug, variant, site, store, regionCode }) => {
  const queryString = window.location.search || '';
  let url = `/product?site=${site}&productId=${id}&productName=${slug}&variantId=${variant}`;
  let tenant = site;
  if (store) {
    url += `&store=${store}&subdomain=${store}`;

    if (regionCode) {
      tenant = `${
        store === constants.STORES.linio || store === constants.STORES.tienda ? 'falabella' : store
      }-${regionCode}`;
    }
  }
  const asUrl = `/${tenant}/product/${id}/${slug}/${variant}${queryString}`;
  Router.replace(url, asUrl, { shallow: true });
};

const navigateWithUrlForCategory = ({
  regionCode,
  site,
  url,
  onRouteChange = noop,
  categoryId,
  categoryName,
  store = null,
}) => {
  const href = getUrlForCategory({ store, regionCode, environment: 'production' });
  const urlSearchParams = new URLSearchParams(url || '');

  urlSearchParams.forEach((value, key) => {
    href.searchParams.set(key, value);
  });

  const asHref = new URL(href);
  asHref.pathname += `/${categoryId}/${categoryName}`;

  href.searchParams.set('site', site);
  href.searchParams.set('id', categoryId);
  href.searchParams.set('name', categoryName);
  if (store) {
    href.searchParams.set('store', store);
  }
  onRouteChange();

  Router.push(`/${href.pathname.split('/')[2]}?${href.searchParams}`, `${asHref.pathname}?${asHref.searchParams}`);
};

const navigateWithUrlForCollection = ({ regionCode, site, url, onRouteChange = noop, collectionId, store }) => {
  const href = getUrlForCollection({ store, regionCode, environment: 'production' });
  const urlSearchParams = new URLSearchParams(url || '');

  urlSearchParams.forEach((value, key) => {
    href.searchParams.set(key, value);
  });

  const asHref = new URL(href);
  asHref.pathname += `/${collectionId}`;

  href.searchParams.set('site', site);
  href.searchParams.set('id', collectionId);
  if (store) {
    href.searchParams.set('store', store);
  }

  onRouteChange();

  Router.push(`/${href.pathname.split('/')[2]}?${href.searchParams}`, `${asHref.pathname}?${asHref.searchParams}`);
};

const navigateWithUrlForSeller = ({ regionCode, site, url, onRouteChange = noop, sellerName, store }) => {
  const href = getUrlForSeller({ store, regionCode, environment: 'production' });
  const urlSearchParams = new URLSearchParams(url || '');
  const seller = encodeURIComponent(sellerName);

  urlSearchParams.forEach((value, key) => {
    href.searchParams.set(key, value);
  });

  const asHref = new URL(href);
  asHref.pathname += `/${seller}`;

  href.searchParams.set('site', site);
  href.searchParams.set('id', sellerName);
  if (store) {
    href.searchParams.set('store', store);
  }

  onRouteChange();
  const queryParams = urlSearchParams.toString();
  Router.push(
    `/${href.pathname.split('/')[2]}?${href.searchParams}`,
    queryParams ? `${asHref.pathname}?${queryParams}` : `${asHref.pathname}`
  );
};

const navigateWithUrlForBrand = ({ regionCode, site, url, onRouteChange = noop, brandName, store }) => {
  const href = getUrlForBrand({ store, regionCode, environment: 'production' });
  const urlSearchParams = new URLSearchParams(url || '');
  const brand = encodeURIComponent(brandName);

  urlSearchParams.forEach((value, key) => {
    href.searchParams.set(key, value);
  });

  const asHref = new URL(href);
  asHref.pathname += `/${brand}`;

  href.searchParams.set('site', site);
  href.searchParams.set('id', brandName);
  if (store) {
    href.searchParams.set('store', store);
  }

  onRouteChange();
  const queryParams = urlSearchParams.toString();
  Router.push(
    `/${href.pathname.split('/')[2]}?${href.searchParams}`,
    queryParams ? `${asHref.pathname}?${queryParams}` : `${asHref.pathname}`
  );
};

const redirectToUrl = (url, res) => {
  if (isWindow()) {
    redirect(url, true);
    return;
  }

  if (res) {
    res.writeHead(constants.HTTP_RES_CODE.TEMP_REDIRECT, {
      Location: url,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Expires: '0',
      Pragma: 'no-cache',
    });
    res.end();
  }
};

const isReloadRequiredOnZoneChange = () => {
  const currentPathName = Router.router.pathname;
  if (currentPathName === '/noResult' && !Router.router.query.Ntt) {
    return false;
  }
  if (currentPathName === '/notFound') {
    return false;
  }
  return true;
};

export {
  changeProductRoute,
  isReloadRequiredOnZoneChange,
  navigate,
  navigateWithUrl,
  navigateWithUrlForBrand,
  navigateWithUrlForCategory,
  navigateWithUrlForCollection,
  navigateWithUrlForSeller,
  redirect,
  redirectToUrl,
  reloadWithParams,
};
