import css from 'styled-jsx/css';

const ShippingBoxButtonStyles = css`
  .shipping-button {
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    display: inline-flex;
    gap: 4px;
    height: 32px;
    justify-content: center;
    margin-top: -4px;
    margin: 3px;
    overflow: hidden;
    padding: 5px 14px;
    position: relative;
    width: fit-content;
    &.selected {
      border: 1.5px solid var(--primary-color);
    }
    &.selected.tottus {
      border-color: var(--teal-shade1);
    }
  }
  /* stylelint-disable font-family-no-missing-generic-family-keyword */
  .shipping-button-text {
    font-family: 'Lato', Helvetica;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: fit-content;
    &.selected {
      font-weight: 700;
    }
  }

  .green-text {
    color: #30871f;
    font-weight: 700;
  }
`;

export { ShippingBoxButtonStyles };
