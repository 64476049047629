const constants = {
  lang: 'lang',
  searchTerm: 'Ntt',
  DEFAULT_LAYOUT: 'LIST',
  SESSION_STORAGE_SCROLL: 'scroll',
  SESSION_STORAGE_NEW_PAGE: 'newPage',
  ZONE: {
    DEFAULT_ZONE_ID: '13',
    DEFAULT_ZONE_NAME: 'R. Metropolitana',
  },
  AD_TYPES_FOR_VAS: {
    BRANDS: 'brands',
    PRODUCTS: 'products',
  },
  STORE_NAMES_FOR_VAS: {
    SO_COM: 'so_com',
    TO_COM: 'to_com',
    SODIMAC: 'sodimac',
    TOTTUS: 'tottus',
    LINIO: 'linio',
    TIENDA: 'tienda',
    HOMECENTER: 'homecenter',
  },
  X_CHANNEL_IDS_HEADERS_VALUES: {
    WEB: 'WEB',
    MOBILE: 'MOBILE',
    TABLET: 'TABLET',
  },
  TENANT_TYPES: {
    FALABELLA_CL: 'falabella-cl',
    FALABELLA_CO: 'falabella-co',
    FALABELLA_PE: 'falabella-pe',
    SODIMAC_CL: 'sodimac-cl',
    SODIMAC_PE: 'sodimac-pe',
    SODIMAC_CO: 'sodimac-co',
  },
  COUNTRIES_ABBREVIATIONS: {
    CHILE: 'cl',
    COLOMBIA: 'co',
    PERU: 'pe',
  },
  SEARCH_REDIRECT_STATUS: 'alt',
  FACETS: {
    CATEGORY_PATH_SEARCH: 'f.product.parentCategoryPaths.pathSearch',
    PRICE_SEARCH_KEY_TOTTUS: 'r.derived.unitPrice',
    PRICE_SEARCH_KEY: 'r.derived.price.search',
    VALUE_TEXT_CHARCOUNT: 50,
  },
  SKIP_LAZY_LOAD_IMAGE_COUNT_MOBILE: 4,
  SKIP_LAZY_LOAD_IMAGE_COUNT_DESKTOP: 8,
  TRACING_HEADERS: [
    'x-request-id',
    'x-b3-traceid',
    'x-b3-spanid',
    'x-b3-parentspanid',
    'x-b3-sampled',
    'x-b3-flags',
    'x-ot-span-context',
  ],
  PAGINATION_TOTAL_RESULTS_LIMIT: 9600,
  DEFAULT_LAYOUT_TYPE: 'HARDLINE',
  PRODUCT_CONSTANTS: {
    HARDLINE: 'HARDLINE',
    SOFTLINE: 'SOFTLINE',
    CONNECT: 'CONNECT',
  },
  HTTP_RES_CODE: {
    TEMP_REDIRECT: 307,
    PERM_REDIRECT: 301,
    NOT_FOUND_CODE: 404,
  },
  DEVICE_DESKTOP: 'desktop',
  DEVICE_MOBILE: 'mobile',
  DEVICE_TABLET: 'tablet',
  PLATFORM_TYPE: {
    ANDROID: 'android',
    IOS: 'ios',
  },
  LAYOUT_2_GRID: '2_GRID',
  LAYOUT_3_GRID: '3_GRID',
  LAYOUT_4_GRID: '4_GRID',
  LAYOUT_1_GRID: '1_GRID',
  LAYOUT_LIST: 'LIST',
  AVAILABILITY_INSTOCK: 'InStock',
  AVAILABILITY_NETWORKSTOCK: 'Network',
  PAGES: {
    PDP: 'PDP',
    PLP: 'PLP',
    SLP: 'SLP',
    PLANS: 'PLANS',
    CART: 'CART',
  },
  VIEW_TEMPLATE: {
    TYPE_A: 'TYPE_A',
    TYPE_C: 'TYPE_C',
  },
  DIGITAL_DATA_STORAGE_NAME: 'analyticsInfo',
  WARRANTY_OPTIONS_MIN_ITEM_COUNT_FOR_DROPDOWN: 5,
  MAX_CART_PRODUCT: 999,
  FALABELLA_REGEX: /falabella/i,
  SODIMAC_REGEX: /sodimac/i,
  HOMECENTER_REGEX: /homecenter/i,
  SO_COM_REGEX: /so_com/i,
  TO_COM_REGEX: /to_com/i,
  TOTUS_REGEX: /tottus/i,
  LINIO_REGEX: /linio_/i,
  FACETS_WITH_SWITCH_REGEX: /(Envío gratis)/i,
  SHIPPING_GROUP_REGEX: /Shipping/,
  EVENT_REGEX: /MULTI_SELECT_EVENT/,
  FREE_SHIPPING_REGEX: /(Envío gratis)/i,
  NEXT_DAY_DELIVERY_REGEX: /(Llega mañana)/,
  SAME_DAY_HD_CC_REGEX: /(Llega hoy|Retira hoy|Hoy|\d+ min)/,
  SHIPPING_CC_REGEX: /(Retira hoy|Retira mañana)/,
  STORES: {
    sodimac: 'sodimac',
    tottus: 'tottus',
    linio: 'linio',
    tienda: 'tienda',
    homecenter: 'homecenter',
  },
  DECIMAL_SEPARATOR: {
    cl: ',',
    co: ',',
    ar: ',',
    pe: '.',
  },
  DOT_SEPARATOR: {
    cl: '.',
    co: '.',
    ar: '.',
    pe: ',',
  },
  SEO_FRIENDLY_FACETS: [
    'f.product.attribute.Tipo',
    'f.product.brandName',
    'f.variant.custom.Tamaño_de_la_pantalla',
    'f.product.attribute.Capacidad_de_carga',
    'f.product.attribute.Tamaño_del_respaldo',
    'f.product.attribute.Material_del_tapiz',
    'f.product.attribute.Material_de_la_estructura',
    'f.product.attribute.Resolución',
    'f.product.attribute.Procesador',
    'f.product.attribute.Número_de_puestos',
    'f.product.attribute.Aro',
    'f.product.attribute.Número_de_hilos',
    'f.product.attribute.Número_de_puertas',
    'f.product.attribute.Número_de_quemadores',
    'f.product.attribute.Memoria_RAM',
    'f.product.attribute.Tipo_de_piel',
    'f.product.attribute.Tamaño_de_la_pantalla',
    'f.product.attribute.Capacidad_de_lavado',
    'f.derived.product.Tamano_de_la_pantalla',
    'f.product.attribute.Cantidad_de_puertas',
    'f.derived.product.Memoria_RAM',
  ],
  DY_ID: {
    staging: {
      cl: 8772169,
      pe: 8774134,
      co: 8774132,
    },
    beta: {
      cl: 8772169,
      pe: 8774134,
      co: 8774132,
    },
    www: {
      cl: 8772169,
      pe: 8774134,
      co: 8774132,
    },
  },
  MEDALLIA: 'https://resources.digital-cloud.medallia.com/wdcus/55961/onsite/embed.js',
  FIT_ANALYTICS: 'https://integrations.fitanalytics.com/shop/falabella/pdp.js',
  DIGITAL_DATA_MANAGER_XLP_SCRIPT: {
    dev: 'https://assets-staging.falabella.com/v3/assets/blt07d653b53a07a055/blt0e1117a5dae9f7bc/xlp.cjs',
    staging: 'https://assets-staging.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/bltf3022ea071e9897c/xlp.cjs',
    beta: 'https://assets-uat.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt4870c50989ab1d66/xlp.cjs',
    www: 'https://assets.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt006a6122094a0f5a/xlp.cjs',
  },
  DIGITAL_DATA_MANAGER_NO_RESULT_SCRIPT: {
    dev: 'https://assets-staging.falabella.com/v3/assets/blt07d653b53a07a055/blt5bfd70100db5c053/noResult.cjs',
    staging: 'https://assets-staging.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blta2518e2baacf2b83/noResult.cjs',
    beta: 'https://assets-uat.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/bltb3b1a8fe31edced4/noResult.cjs',
    www: 'https://assets.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/bltc1b0c6ffbc670416/noResult.cjs',
  },
  VWO_SCRIPTS: {
    cl: 'https://assets.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt3ae1beb67525ed26/vwo.js',
    pe: 'https://assets.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/bltf1762da3c97b64e4/vwo.js',
    co: 'https://assets.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/bltf0b1376d26dd28cc/vwo.js',
  },
  COMMERCE_API_ALERTS: {
    AL013: {
      message: 'No se pudo agregar el ID de producto',
    },
    CART_CONFIG_BREACH: {
      message: 'Se superó la cantidad máxima permitida por producto',
    },
    AL003: {
      message: 'Precio no encontrado',
    },
    AL004: {
      message: 'El inventario está agotado',
    },
    AL005: {
      message: 'Inventario no encontrado',
    },
    CHECKOUT_SLOT_NOT_AVAILABLE: {
      message: 'Lo sentimos. Intenta con otra comuna o revisa la disponibilidad del envío a domicilio.',
    },
  },
  COMMERCE_API_ERRORS: {
    CART_CONFIG_BREACH: {
      message: 'Se superó la cantidad máxima permitida por producto',
    },
    CHECKOUT_SLOT_NOT_AVAILABLE: {
      message: 'Lo sentimos. Intenta con otra comuna o revisa la disponibilidad del envío a domicilio.',
    },
    AL001: {
      message: 'El vendedor subió el precio de este producto.',
    },
    AL002: {
      message: '¡Buena noticia! El vendedor bajó el precio de este producto.',
    },
    AL003: {
      message: 'Tuvimos un problema, no pudimos cargar el precio.',
    },
    AL004: {
      message: 'Lo sentimos, este producto recién se agotó.',
    },
    AL005: {
      message: 'Tuvimos un problema, no encontramos las unidades disponibles.',
    },
    AL006: {
      message: 'Lo sentimos, el vendedor despublicó este producto.',
    },
    AL007: {
      message: 'Se aplicó la promoción.',
    },
    AL008: {
      message: 'Se eliminó la promoción.',
    },
    AL009: {
      message: 'Tuvimos un problema, intenta agregar al Carro nuevamente.',
    },
    AL010: {
      message: 'El cupón no es válido.',
    },
    AL011: {
      message: 'Agregamos el cupón con éxito.',
    },
    AL012: {
      message: 'Eliminamos el cupón.',
    },
    AL013: {
      message: 'El cupón expiró.',
    },
    AL014: {
      message: 'Superaste la cantidad máxima de unidades.',
    },
    AL015: {
      message: 'Superaste la cantidad máxima de unidades.',
    },
    AL016: {
      message: 'Superaste la cantidad máxima de unidades.',
    },
    AL017: {
      message: 'Superaste la cantidad máxima de productos que puedes llevar en tu Carro.',
    },
    AL018: {
      message: 'Tuvimos un problema, intenta agregar al Carro nuevamente.',
    },
    AL019: {
      message: 'Tuvimos un problema, intenta agregar al Carro nuevamente.',
    },
    AL020: {
      message: 'Superaste la cantidad máxima de unidades.',
    },
    AL021: {
      message: 'Tuvimos un problema, intenta agregar al Carro nuevamente.',
    },
    AL022: {
      message: 'Tuvimos un problema, intenta agregar al Carro nuevamente.',
    },
    AL023: {
      message: 'Tuvimos un problema, intenta agregar al Carro nuevamente.',
    },
    AL024: {
      message: 'Tuvimos un problema, intenta agregar al Carro nuevamente.',
    },
    AL025: {
      message: 'Lo sentimos, este producto recién se agotó.',
    },
  },
  COMMERCE_LIMIT_API_ALERTS: {
    AL023: {
      message: 'Has alcanzado la cantidad máxima para este producto.',
    },
    AL021: {
      message: 'Has alcanzado la cantidad máxima de productos para tu Bolsa de compras',
    },
  },
  COMMERCE_LIMIT_API_ERRORS: {
    AL023: {
      message: 'Has alcanzado la cantidad máxima para este producto.',
    },
    AL021: {
      message: 'Has alcanzado la cantidad máxima de productos para tu Bolsa de compras',
    },
  },
  DEFAULT_ZONES: 'AFZone',
  ANDES_DEF_ZONE: {
    politicalId: '15c37b0b-a392-41a9-8b3b-978376c700d5',
    priceGroupId: '96',
    zones: ['ZL_CERRILOS', 'LOSC', '130617', '13'],
    oldZones: ['LOSC', '130617', '13'],
    name: 'Seleccion ubicacion',
  },
  DEF_ZONE: {
    cl: {
      staging: {
        politicalId: '4856187d-c035-45ac-9f4e-9290b9e9f85f',
        priceGroupId: '96',
        zones: ['ZL_CERRILOS', 'LOSC', '130617', '13'],
        name: 'Seleccion ubicacion',
      },
      prod: {
        politicalId: '15c37b0b-a392-41a9-8b3b-978376c700d5',
        priceGroupId: '96',
        zones: ['ZL_CERRILOS', 'LOSC', '130617', '13'],
        name: 'Seleccion ubicacion',
      },
      beta: {
        politicalId: '15c37b0b-a392-41a9-8b3b-978376c700d5',
        priceGroupId: '96',
        zones: ['ZL_CERRILOS', 'LOSC', '130617', '13'],
        name: 'Seleccion ubicacion',
      },
    },
    pe: {
      staging: {
        politicalId: 'c6b7a50c-60f3-4687-91d0-9461d538812a',
        priceGroupId: '2',
        zones: ['150000'],
        name: 'Seleccion ubicacion',
      },
      prod: {
        politicalId: '799c102f-9b4c-44be-a421-23e366a63b82',
        priceGroupId: '2',
        zones: ['150000', '180000'],
        name: 'Seleccion ubicacion',
      },
      beta: {
        politicalId: '799c102f-9b4c-44be-a421-23e366a63b82',
        priceGroupId: '2',
        zones: ['150000', '180000'],
        name: 'Seleccion ubicacion',
      },
    },
    co: {
      staging: {
        politicalId: '59a6aec4-fc51-4f3b-ab5e-62fd7a1076c0', // TBD
        priceGroupId: '19',
        zones: ['14'],
        name: 'Seleccion ubicacion',
      },
      prod: {
        politicalId: '764bc3a7-0a78-4409-b2b5-a92606b325d4',
        priceGroupId: '19',
        zones: ['14'],
        name: 'Seleccion ubicacion',
      },
      beta: {
        politicalId: '764bc3a7-0a78-4409-b2b5-a92606b325d4',
        priceGroupId: '19',
        zones: ['14'],
        name: 'Seleccion ubicacion',
      },
    },
  },
  ATC_SESSION_EXPIRED_CODE: 'CHECKOUT_INVALID_SESSION',
  RELATED_KEYWORDS_HEADING: 'Otras personas también buscaron:',
  CATEGORY: 'Categoría',
  ICON_CLASSES_MAPPING: {
    'f.range.product.averageOverallRating': 'csicon-star_full_filled star',
  },
  BRAND_FACET: /f.product.brandName/i,
  SKIP_ALERT_CODES: 'AL004,AL005,AL022,AL024',
  IS_USER_OVER_18: 'isUserOver18',
  SHIPPING_FACET_NAMES: ['Llega hoy', 'Llega mañana', 'Retira hoy', 'Retira mañana'],
  SB_EXPERIMENT_POSITION: 'SB_EXPERIMENT_POSITION',
  SB_EXPERIMENT_MAX_ELEMENTS: 'SB_EXPERIMENT_MAX_ELEMENTS',
};

export default constants;
