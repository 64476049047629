import apiConfig from '../../config/api/apiConfig';
import constants from '../../config/constants';
import CookieStorage from '../../utils/CookieStorage';
import _ from '../../utils/LodashImports';
import WebStorage from '../../utils/WebStorage';
import httpService from '../../utils/httpService';
import { getCommerceHeaders } from '../../utils/tenantHelper';
import { getCountry } from '../util';
import { getBreadcrumbText, getCatalystPage, getPageName, getPageType } from './pageInformation';

const getSections = () => {
  const breadcrumbText = getBreadcrumbText();
  const sections = breadcrumbText.split(': ');
  const [siteSection, subSection, subSection2] = sections;
  const result = { siteSection, subSection, subSection2 };
  Object.keys(result).forEach((key) => !result[key] && delete result[key]);
  return result;
};

const getPreviousUrl = () => {
  return window.previousUrl || document.referrer;
};

const updateDigitalData = () => {
  window.digitalData.page.pageName = getPageName();
  window.digitalData.page.pageType = getPageType();
  window.digitalData.page.catalyst = getCatalystPage();
  window.digitalData.page.previousUrl = getPreviousUrl();
  const sections = getSections();
  Object.assign(window.digitalData.page, sections);
};

const getDigitalDataFromStorage = () => {
  return JSON.parse(
    WebStorage.getItem({
      name: constants.DIGITAL_DATA_STORAGE_NAME,
      fromPersistence: true,
    })
  );
};

const saveDigitalData = (digitalData) => {
  WebStorage.setItem({
    name: constants.DIGITAL_DATA_STORAGE_NAME,
    shouldPersist: true,
    value: JSON.stringify(digitalData),
  });
};

const shouldLoadAnalyticsInfo = () => {
  const storedDigitalData = getDigitalDataFromStorage();
  if (!storedDigitalData || !storedDigitalData.login || _.isEmpty(storedDigitalData.login)) {
    return true;
  }
  const userCookie = CookieStorage.getUserDataCookieName();
  const sessionCookie = (CookieStorage.getCookie(userCookie) || '*').split('*')[0];
  return !!(storedDigitalData.login.emailHash ? !sessionCookie : sessionCookie);
};

const getAnalyticsInfoFromCatalyst = async (country) => {
  const analyticsInfoUrl = apiConfig.getCommerceAnalyticsInfoUrl(country);

  const defaultResponse = {
    data: { analyticsInfo: { page: {}, login: {} } },
  };

  const commerceHeaders = getCommerceHeaders(country, false);

  const headers = commerceHeaders;

  const sid = CookieStorage.getCookie('sid');

  if (sid) {
    headers.Authorization = sid;
  }

  const serverResponse = await httpService().get(analyticsInfoUrl, {
    includeTracing: false,
    headers,
  });

  return serverResponse.error ? defaultResponse : serverResponse;
};

const initDigitalData = async () => {
  if (shouldLoadAnalyticsInfo()) {
    const country = getCountry().toLowerCase();

    const promise = getAnalyticsInfoFromCatalyst(country);

    const {
      data: { analyticsInfo },
    } = await promise;

    saveDigitalData(analyticsInfo);
    window.digitalData = { ...window.digitalData, ...analyticsInfo };
  } else {
    const digitalData = getDigitalDataFromStorage();
    window.digitalData = { ...window.digitalData, ...digitalData };
  }
  updateDigitalData();
};

export const emitDigitalDataEvent = (eventName, payload) => {
  if (window.digitalData) {
    window.dispatchEvent(
      new CustomEvent(eventName, {
        bubbles: true,
        detail: payload,
      })
    );
  }
};

export { initDigitalData, updateDigitalData };
