import PropTypes from 'prop-types';
import React from 'react';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withLabels } from '../../../utils/LabelsContext';
import _ from '../../../utils/LodashImports';
import SkipSSRFacets from '../../../utils/SkipSSRFacets';
import noop from '../../../utils/noop';
import Accordion from '../../ui/Accordion/Accordion';
import { resetDigitalData } from '../Common/digitalData';
import getFacetsData from '../Common/getFacetsData';
import { facetClick } from '../FacetEvents';
import FacetsWithSwitch from '../FacetsWithSwitch/FacetsWithSwitch';
import { facetsDesktopStyles } from './FacetsDesktop.style';

const RenderFacet = ({ children, variant, type }) => (
  <div className={`facetsDesktop ${variant}`} id={`testId-${type}-desktop-container`}>
    <div className="facetsDesktop-body">{children}</div>
    <style jsx>{facetsDesktopStyles}</style>
  </div>
);
RenderFacet.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

const FacetsDesktop = ({
  data,
  dataMethods,
  labels,
  pageName,
  appCtx,
  onFacetChangeHandler,
  getUpdatedFacets,
  canonicalUrl,
}) => {
  if (!data.length) return null;
  const { type, tenant, baseFacetsURL, changeURL } = dataMethods;
  const searchInFacetsLabel = labels.SEARCH_IN_FACETS_LABEL;
  const priceRangeErrorLabel = labels.ENTER_VALID_PRICE_ERROR_LABEL;
  const priceRangeNoResultsLabel = labels.PRICE_NO_RESULTS_ERROR_LABEL;

  const hasCustomVisualsForItem = () => {
    return type === 'availability';
  };

  const isShippingFacetVisible = _.get(appCtx, 'siteConfig.toggles.isShippingFacetVisible', false);

  const onFacetChange = (obj, resetDD) => {
    facetClick();
    const { url } = obj;
    const urls = [];
    if (baseFacetsURL) {
      urls.push(baseFacetsURL);
    }
    if (url) {
      const facetURL = url.split('&').filter((u) => !u.match(/facetSelected/g));
      if (facetURL.length > 0) {
        urls.push('facetSelected=true', facetURL.join('&'));
      }
    }
    changeURL(urls.join('&'));
    onFacetChangeHandler();
    if (resetDD) {
      resetDigitalData();
    }
  };

  const facetData = data
    .map(
      getFacetsData({
        groupType: type,
        tenant,
        onFacetChange,
        baseFacetsURL,
        changeURL,
        priceRangeErrorLabel,
        priceRangeNoResultsLabel,
        searchInFacetsLabel,
        pageName,
        hideMenuFacets: noop,
        appCtx,
        getUpdatedFacets,
        canonicalUrl,
      })
    )
    .map((item) => {
      // eslint-disable-next-line no-param-reassign
      item.content = item.state ? item.content : SkipSSRFacets(item.content);
      return item;
    });

  switch (type) {
    case 'facetsWithSwitch': {
      return <FacetsWithSwitch items={facetData} />;
    }
    case 'availability': {
      if (isShippingFacetVisible) {
        return (
          <RenderFacet variant="shipping" type={type}>
            {!!facetData.length && facetData[0].content()}
          </RenderFacet>
        );
      }
      return (
        <RenderFacet variant="availability" type={type}>
          <Accordion items={facetData} hasCustomVisualsForItem={hasCustomVisualsForItem()} />
        </RenderFacet>
      );
    }
    default:
      return (
        <RenderFacet type={type}>
          <Accordion items={facetData} />
        </RenderFacet>
      );
  }
};

FacetsDesktop.defaultProps = {
  pageName: 'search',
  data: [],
  canonicalUrl: '',
};

FacetsDesktop.propTypes = {
  dataMethods: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  pageName: PropTypes.string,
  onFacetChangeHandler: PropTypes.func.isRequired,
  getUpdatedFacets: PropTypes.func.isRequired,
  canonicalUrl: PropTypes.string,
};

export default withApplicationContext(withLabels(FacetsDesktop));
export { FacetsDesktop };
