import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

export const alertIconSvg =
  '<?xml version="1.0" encoding="UTF-8"?><svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- Generator: sketchtool 51.3 (57544) - http://www.bohemiancoding.com/sketch --><title>CC94A0C3-EF13-4864-8070-1CD87B400E9C</title><desc>Created with sketchtool.</desc><defs><path d="M27,13.5 C34.425,13.5 40.5,19.575 40.5,27 C40.5,34.425 34.425,40.5 27,40.5 C19.575,40.5 13.5,34.425 13.5,27 C13.5,19.575 19.575,13.5 27,13.5 Z M26.9999996,15.3 C20.4749998,15.3 15.3,20.4749998 15.3,26.9999996 C15.3,33.5249994 20.4749998,38.6999992 26.9999996,38.6999992 C33.5249994,38.6999992 38.6999992,33.5249994 38.6999992,26.9999996 C38.6999992,20.4749998 33.5249994,15.3 26.9999996,15.3 Z M26.0999998,19.125 L27.8999998,19.125 L27.8999998,30.375 L26.0999998,30.375 L26.0999998,19.125 Z M27.0000003,35.0999992 C26.2544158,35.0999992 25.6500002,34.4955836 25.6500002,33.7499992 C25.6500002,33.0044148 26.2544158,32.3999991 27.0000003,32.3999991 C27.7455847,32.3999991 28.3500003,33.0044148 28.3500003,33.7499992 C28.3500003,34.4955836 27.7455847,35.0999992 27.0000003,35.0999992 Z" id="path-1"></path></defs><g id="CASOS-BUY-SECTION---1280px-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="PDPM-BS14-SoftCategory-AddToCartWhitoutSize-1" transform="translate(-19.000000, -19.000000)"><g id="POPUP" transform="translate(-1.000000, 0.000000)"><g id="icon/alert/alert" transform="translate(7.000000, 6.000000)"><mask id="mask-2" fill="white"><use xlink:href="#path-1"></use></mask><use id="Shape" fill="#BBBBBB" fill-rule="nonzero" xlink:href="#path-1"></use><g id="Color/Silver" mask="url(#mask-2)" fill="#FFC000" fill-rule="nonzero"><g transform="translate(-6.750000, -6.750000)" id="Amber"><rect x="0" y="0" width="67.5" height="67.5"></rect></g></g></g></g></g></g></svg>';

const ProductVariationsStyles = css`
  .popup {
    height: calc(100vh - 120px);
    cursor: initial;
    background-color: ${colors.white.shade1};
    display: flex;
    flex-direction: column;
    @mixin tabletToDesktop {
      height: auto;
      width: auto;
    }
  }

  .header {
    margin: 12px 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${colors.gray.shade14};
    padding-bottom: 8px;

    @mixin tabletToDesktop {
      height: 36px;
      margin: 12px 32px 12px 28px;
      padding-bottom: 0;
    }

    .csicon-icon-success-circle {
      color: ${colors.green.shade1};
      font-size: ${typography.xl3a};
    }

    .label {
      flex-grow: 1;
      margin: 0 14px;
      line-height: 22px;
    }

    .csicon-danger {
      color: ${colors.gray.shade29};
      font-size: ${typography.sm};
    }

    .warning-icon {
      width: 55px;
      height: 27px;
      background-image: url('${svgToDataUri(alertIconSvg)}');
      background-size: contain;
      background-repeat: no-repeat;
      @mixin tabletToDesktop {
        width: 27px;
      }
    }
  }

  .body {
    overflow-x: hidden;
    flex-grow: 1;

    @mixin tabletToDesktop {
      height: auto;
    }

    .collections,
    .bundles {
      border-top: 1px solid ${colors.gray.shade14};

      span {
        margin: 22px 0;
        display: block;
      }
    }

    .collections {
      padding-top: 22px;
    }
  }

  .product-details {
    display: flex;
    border-bottom: 1px solid ${colors.gray.shade55};
    margin: 12px 32px 12px 28px;

    @mixin mobileToTablet {
      margin: 12px 20px;
    }
    .product-image {
      box-sizing: border-box;
      height: 89px;
      width: 89px;
      border: 1px solid ${colors.gray.shade43};
      @mixin tabletToDesktop {
        height: 46px;
        width: 46px;
      }
    }
    .item-info {
      margin: 0 18px;
    }
    .info-wrapper {
      display: flex;
      flex-direction: column;
      @mixin tabletToDesktop {
        flex-direction: row;
        gap: 24px;
      }
    }
  }

  .price {
    margin: 12px 18px 0;
    @mixin tabletToDesktop {
      margin: 0;
    }
  }

  .variant-details {
    display: flex;
    padding: 12px 20px;
    flex-direction: column;
    @mixin tabletToDesktop {
      flex-direction: row;
      margin: 12px 32px 12px 28px;
      padding: 0;
      padding-bottom: 134px;
    }

    .color-variants {
      width: 100%;
      margin-right: 28px;
      @mixin tabletToDesktop {
        width: 200px;
      }
    }
    .size-variants {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0;
      @mixin tabletToDesktop {
        width: 330px;
        margin: 0;
      }
      .sizes-group {
        display: flex;
        flex-wrap: wrap;
        margin-top: 8px;
      }
      .size-title {
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        @mixin tabletToDesktop {
          font-size: 14px;
        }
      }
      .size-chart-data {
        color: #717171;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
  .addToCart-container {
    background-color: ${colors.white.shade1};

    @mixin tabletToDesktop {
      background: #fbfbfb;
      width: 100%;
      display: flex;
      height: 72px;
      justify-content: flex-end;
      padding-right: 25px;
      bottom: 0;
      padding-top: 20px;
    }

    @mixin mobileToTablet {
      position: fixed;
      padding: 25px;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
    }

    .cerrar-button {
      margin-right: 20px;
      font-size: ${typography.base};
      .cerrar-button-text {
        letter-spacing: 0.19px;
        line-height: 16px;
        padding-bottom: 1px;
        border-bottom: 0.5px solid ${colors.black.shade9};
      }
      @mixin mobileToTablet {
        margin-top: 13px;
      }
    }
  }

  .addToCart-container-mkp {
    background-color: ${colors.gray.shade41};
    border: 1px solid #f0f0f0;

    @mixin tabletToDesktop {
      background: #fbfbfb;
      width: 100%;
      display: flex;
      height: 72px;
      justify-content: flex-end;
      padding-right: 21px;
      bottom: 0;
      padding-top: 16px;
    }

    @mixin mobileToTablet {
      position: fixed;
      padding: 20px 24px 20px 23px;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
    }

    .cerrar-button {
      margin-right: 20px;
      font-size: ${typography.base};
      .cerrar-button-text {
        letter-spacing: 0.19px;
        line-height: 16px;
        padding-bottom: 1px;
        border-bottom: 0.5px solid ${colors.black.shade9};
      }
      @mixin tabletToDesktop {
        padding-bottom: 16px;
      }
      @mixin mobileToTablet {
        margin-top: 4px;
        margin-right: none;
      }
    }
  }

  .add-to-cart-error {
    button {
      background-color: ${colors.black.shade9};
    }
  }
  .action-buttons {
    @mixin tabletToDesktop {
      margin-bottom: 10px;
      display: flex;
      right: 30px;
      width: 36.5%;
    }
    @mixin mobileToTablet {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .action-buttons-mkp {
    @mixin tabletToDesktop {
      margin-bottom: 10px;
      display: flex;
      right: 30px;
      width: auto;
    }
    @mixin mobileToTablet {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column-reverse;
    }
  }
`;
export { ProductVariationsStyles };
