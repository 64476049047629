/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import constants from '../../config/constants';
import {
  sponsoredClickService,
  sponsoredImpressionService,
  sponsoredVisitService,
} from '../../services/sponsoredService';
import CookieStorage from '../../utils/CookieStorage';
import _ from '../../utils/LodashImports';
import sponsoredEvents from '../../utils/SponsoredHelper';
import WithRouter from '../../utils/WithRouter';
import { isWindow } from '../../utils/deviceChecker';

const setSponsoredDisplayDigitalDataImpression = (mPos, sku) => {
  const SPONSORED_DISPLAY_POSITION = {
    9: 'bottom',
    10: 'side',
  };
  const eventToDigitalData = new CustomEvent('DDXLPSponsoredDisplayImpression', {
    bubbles: true,
    detail: {
      payload: `${sku}|${SPONSORED_DISPLAY_POSITION[mPos]}`,
    },
  });
  window.dispatchEvent(eventToDigitalData);
};

const setSponsoredDisplayDigitalDataClick = (mPos, sku) => {
  const SPONSORED_DISPLAY_POSITION = {
    9: 'bottom',
    10: 'side',
  };
  const eventToDigitalData = new CustomEvent('DDXLPSponsoredDisplayClick', {
    bubbles: true,
    detail: {
      payload: `${sku}|${SPONSORED_DISPLAY_POSITION[mPos]}`,
    },
  });
  window.dispatchEvent(eventToDigitalData);
};
const getFuntionAnalyticsSponsored = (isSponsoredService) => {
  if (isSponsoredService)
    return {
      sponsoredClickService,
      sponsoredImpressionService,
      sponsoredVisitService,
    };

  return {
    sponsoredClickService: sponsoredEvents.click,
    sponsoredImpressionService: sponsoredEvents.impression,
    sponsoredVisitService: sponsoredEvents.visit,
  };
};

const getPageId = (pageName) => {
  const pagesIds = {
    category: 'categoryId',
    search: 'Ntt',
  };

  return pagesIds[pageName] || '';
};

const getIsSponsoredDisplayEnabled = (appCtx) => {
  const { siteConfig, store = 'falabella' } = appCtx;

  const featureEnableSponsoredDisplay = _.get(siteConfig, 'toggles.featureEnableSponsoredDisplay', false);

  const disabledStoresSponsoredProductsJson = _.get(siteConfig, 'configurations.disabledStoresSponsoredProducts', {});

  const disabledStoresSponsoredProducts = _.get(disabledStoresSponsoredProductsJson, 'stores', []);

  const isSponsoredDisplayEnabled = featureEnableSponsoredDisplay && !disabledStoresSponsoredProducts.includes(store);

  return isSponsoredDisplayEnabled;
};

const getUrl = (url, store) => {
  const storesAvaliable = ['tottus', 'linio', 'sodimac', 'homecenter'];

  if (storesAvaliable.includes(store)) return `${url}?exp=${store}`;

  return url;
};

const getSponsorBrandCookie = (appCtx) => {
  const sbExperimentPositionName = constants.SB_EXPERIMENT_POSITION;
  console.log({ sbExperimentPositionName });
  let sbExperimentPosition;
  console.log({ cookies: appCtx.ssrCookies });
  if (isWindow()) {
    sbExperimentPosition = CookieStorage.getCookie(sbExperimentPositionName);
    try {
      sbExperimentPosition = JSON.parse(sbExperimentPosition);
    } catch {
      sbExperimentPosition = undefined;
    }
  } else {
    const cookies = CookieStorage.parseCookies(appCtx.ssrCookies);
    sbExperimentPosition = cookies?.[sbExperimentPositionName];
  }
  console.log({ sbExperimentPosition });

  if (!sbExperimentPosition) {
    return undefined;
  }
  return sbExperimentPosition;
};

function getShouldShowSponsoredBrand({ appCtx, pageName, name, deviceType, index }) {
  console.log('---INIT TESTING SB V2 ----');
  const isSponsorBrandEnabled = _.get(appCtx, 'siteConfig.toggles.isSponsorBrandEnabled', false);
  if (!isSponsorBrandEnabled) return false;

  const sponsorBrandPosition =
    getSponsorBrandCookie(appCtx) || _.get(appCtx, 'siteConfig.configurations.vasConfig.sponsorBrandPosition', {});
  console.log({ sponsorBrandPosition });

  const desktopItemsPerRow = 4;
  const mobileItemsPerRow = 2;
  const defaultSponsorBrandPosition = {
    desktop: 3 * desktopItemsPerRow,
    mobile: 6 * mobileItemsPerRow,
    tablet: 6 * mobileItemsPerRow,
  };
  console.log({ pageName, name, deviceType, index });

  const isSearchPage = pageName === 'search';
  const positionPerDeviceType = isSearchPage
    ? sponsorBrandPosition.search || defaultSponsorBrandPosition
    : sponsorBrandPosition[name] || defaultSponsorBrandPosition;

  const indexForSB = positionPerDeviceType[deviceType];
  console.log({ index, indexForSB });
  const shouldShowSponsoredBrand = index === indexForSB;
  const itemsPerRow = deviceType === 'desktop' ? desktopItemsPerRow : mobileItemsPerRow;
  const rowNumber = Math.ceil((indexForSB + 1) / itemsPerRow);
  console.log({ getShouldShowSponsoredBrandResponse: { shouldShowSponsoredBrand, rowNumber } });

  return { shouldShowSponsoredBrand, rowNumber };
}

export const dispatchDigitalData = (eventName, payload) => {
  const event = new CustomEvent(eventName, {
    bubbles: true,
    detail: { payload },
  });
  window.dispatchEvent(event);
};

export const sendDigitalDataForRender = (brandName, position) => {
  const payload = `${brandName}|${position}`;

  dispatchDigitalData('DDXLPSponsoredBrandRender', payload);
};

export const sendDigitalDataForImpression = (brandName, position, totalProducts) => {
  const payload = `${brandName}|${position}|${totalProducts}`;

  dispatchDigitalData('DDXLPSponsoredBrandImpression', payload);
};

export const sendDigitalDataForClick = (brandName, position, totalProducts, skuClicked) => {
  const elementType = skuClicked ? 'product' : 'custom image';
  const skuOrImage = skuClicked || 'image';
  const payload = `${brandName}|${position}|${totalProducts}|${elementType}|${skuOrImage}`;

  dispatchDigitalData('DDXLPSponsoredBrandClick', payload);
};

const handleFetchComplete = (rowNumber, brandNameRef, SBproductLength) => (data) => {
  if (data?.sponsoredProducts?.length) {
    const brandName = data.sponsoredProducts[0]?.brand || '';
    const productLength = Math.min(data.sponsoredProducts.length, 3);
    brandNameRef.current = brandName;
    SBproductLength.current = productLength;
    sendDigitalDataForRender(brandName, rowNumber);
  }
};

const getHandleBannerActionForSponsorDisplay = ({
  appCtx,
  config,
  categoryName,
  mPos,
  hasImpressionBeenSent,
  setHasImpressionBeenSent,
}) => {
  const { tenant, deviceType, regionCode, store } = appCtx;

  const { MABAYA_TOKEN, MABAYA_EVENTS_ENABLED, FEATURE_TOGGLE_SPONSORED_VAS_SERVICE } = config;

  const handleBannerAction = (product, action) => {
    /* istanbul ignore next */
    if (product != null) {
      const sku = product.id;
      const { adInfo } = product;
      const params = {
        mabayaToken: MABAYA_TOKEN,
        enabled: MABAYA_EVENTS_ENABLED,
        platform: deviceType,
        referrerUrl: window.location.href,
        sku,
        regionCode,
        adInfo,
        mPos,
        categoryName,
        tenant,
      };
      const isSponsoredService = FEATURE_TOGGLE_SPONSORED_VAS_SERVICE || false;
      const {
        sponsoredClickService: clickService,
        sponsoredImpressionService: impressionService,
        sponsoredVisitService: visitService,
      } = getFuntionAnalyticsSponsored(isSponsoredService);

      if (action === 'click') {
        clickService(params);
        setSponsoredDisplayDigitalDataClick(mPos, sku);
      }
      if (action === 'impression' && !hasImpressionBeenSent) {
        impressionService(params);
        setSponsoredDisplayDigitalDataImpression(mPos, sku);
        setHasImpressionBeenSent(true);
      }

      if (action === 'visit') {
        visitService(params);
      }
    }
  };

  return handleBannerAction;
};

const getHandleBrandActionForSponsoredBrand = ({
  appCtx,
  config,
  categoryName,
  hasImpressionBeenSent,
  setHasImpressionBeenSent,
  pageName,
  brandNameRef,
  SBproductLength,
}) => {
  const { tenant, deviceType, regionCode, store } = appCtx;
  const { MABAYA_TOKEN, MABAYA_EVENTS_ENABLED, FEATURE_TOGGLE_SPONSORED_VAS_SERVICE } = config;
  const { BRANDS } = constants.AD_TYPES_FOR_VAS;
  const name = WithRouter('name');
  const { rowNumber } = getShouldShowSponsoredBrand({
    appCtx,
    pageName,
    categoryName,
    deviceType,
    name,
  });
  const handleBrandAction = (product, eventType, mabayaAdInfo) => {
    const mabayaAdInfoString = mabayaAdInfo.join(',');
    const params = {
      mabayaToken: MABAYA_TOKEN,
      adType: BRANDS,
      enabled: MABAYA_EVENTS_ENABLED,
      platform: deviceType,
      referrerUrl: window.location.href,
      regionCode,
      adInfo: mabayaAdInfoString,
      categoryName,
      tenant,
    };

    const isSponsoredService = FEATURE_TOGGLE_SPONSORED_VAS_SERVICE || false;

    const { sponsoredClickService: clickService, sponsoredImpressionService: impressionService } =
      getFuntionAnalyticsSponsored(isSponsoredService);

    if (eventType === 'click') {
      clickService(params);
      const sku = product?.id;
      sendDigitalDataForClick(brandNameRef.current, rowNumber, SBproductLength.current, sku);
    }

    if (eventType === 'impression' && !hasImpressionBeenSent) {
      sendDigitalDataForImpression(brandNameRef.current, SBproductLength.current, rowNumber);
      impressionService(params);
      setHasImpressionBeenSent(true);
    }
  };

  return handleBrandAction;
};

const getBaseDomain = (url) => {
  if (typeof url !== 'string') {
    return null;
  }
  const urlObj = new URL(url);
  return urlObj.origin;
};

const getUrlBase = (config) => {
  const urlBase = isWindow() ? config.API_HOST : config.CLUSTER_API_HOST;

  return getBaseDomain(urlBase);
};

let cachedFacetParams = {};
let cachedSearch = '';

const getFacetParams = () => {
  if (typeof window !== 'undefined') {
    const currentSearch = window.location.search;
    if (currentSearch === cachedSearch) {
      return cachedFacetParams;
    }
    cachedSearch = currentSearch;
    const urlParams = new URLSearchParams(window.location.search);
    cachedFacetParams = {};
    urlParams.forEach((value, key) => {
      cachedFacetParams[key] = value;
    });
    return cachedFacetParams;
  }
  return {};
};

const getMaxSponsorBrandCookie = (appCtx) => {
  const sbExperimentMaxName = constants.SB_EXPERIMENT_MAX_ELEMENTS;
  const cookies = CookieStorage.parseCookies(appCtx.ssrCookies);
  const sbExperimentMax = cookies?.[sbExperimentMaxName];

  if (!sbExperimentMax) {
    return undefined;
  }
  return Number(sbExperimentMax);
};

export {
  getFacetParams,
  getFuntionAnalyticsSponsored,
  getHandleBannerActionForSponsorDisplay,
  getHandleBrandActionForSponsoredBrand,
  getIsSponsoredDisplayEnabled,
  getMaxSponsorBrandCookie,
  getPageId,
  getShouldShowSponsoredBrand,
  getUrl,
  getUrlBase,
  handleFetchComplete,
};
