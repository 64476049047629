import css from 'styled-jsx/css';
import { colors, icons, typography, zIndex } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

const tooltipMargin = 16;
const caretHeight = 20;
const caretWidth = caretHeight * 2;
export const TooltipStyleStyles = css`
  .tooltipParent {
    display: flex;
    &.default {
      position: relative;
    }
    .pointer {
      display: none;
    }
    .tooltipContainer {
      position: absolute;
      background-color: ${colors.white.shade1};
      box-shadow: 0 5px 45px rgba(0, 0, 0, 0.07);
      border: 1px solid ${colors.white.shade1};
      border-radius: 3px;
      margin: ${tooltipMargin}px 0;
      box-sizing: border-box;
      display: none;
      z-index: ${zIndex.popover.content};
      &.show-without-hover {
        display: block;
      }
      &.bottom {
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
      &.middle-1 {
        transform: translate(-50%, -50%);
      }
      &.bottom-middle {
        top: 100%;
        left: 55%;
        transform: translateX(-55%);
        @mixin mobileToTablet {
          left: 49%;
        }
      }
      &.bottom-middle-1 {
        top: 100%;
        left: 45%;
        transform: translateX(-35%);
      }
      &.bottom-left {
        top: 100%;
        left: 50%;
        transform: translateX(-85%);
      }
      &.bottom-left-1 {
        top: 100%;
        left: 50%;
        transform: translateX(-85%);
      }
      &.right {
        left: 100%;
        top: 50%;
        transform: translateY(calc(-50% - ${tooltipMargin}px));
      }
      &.left {
        right: 100%;
        top: 50%;
        transform: translateY(calc(-50% - ${tooltipMargin}px));
      }
      &.top {
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
      &.top-left {
        bottom: 100%;
        left: 50%;
        transform: translateX(-100%);
      }
      &.top-middle {
        bottom: 100%;
        left: 50%;
        transform: translateX(-65%);
      }
      &.top-caret-left {
        bottom: 100%;
        left: 50%;
        transform: translateX(-46%);
      }
      .caret {
        position: absolute;
        height: 20px;
        width: 40px;
        overflow: hidden;
        &.top {
          top: 100%;
          left: 50%;
          transform: translateX(-${tooltipMargin + caretWidth / 2}px) rotate(180deg);
        }
        &.bottom {
          bottom: 100%;
          left: 50%;
          transform: translateX(-${tooltipMargin + caretWidth / 2}px);
        }
        &.middle-1 {
          bottom: 100%;
          left: 60%;
          transform: translateX(-${tooltipMargin + caretWidth / 2}px);
        }
        &.bottom-left-1 {
          bottom: 100%;
          left: 45%;
          transform: translateX(-${tooltipMargin + caretWidth / 2}px);
        }
        &.bottom-middle {
          bottom: 100%;
          left: 55%;
          transform: translateX(-34px);
        }
        &.bottom-middle-1 {
          bottom: 100%;
          left: 55%;
          transform: translateX(-34px);
        }
        &.bottom-left {
          bottom: 100%;
          left: 86%;
          transform: translateX(-${tooltipMargin + caretWidth / 2}px);
        }
        &.left {
          left: 100%;
          top: 50%;
          margin-left: -${caretHeight / 2}px;
          transform: translateY(-50%) rotate(90deg);
        }
        &.right {
          right: 100%;
          top: 50%;
          margin-right: -${caretHeight / 2}px;
          transform: translateY(-50%) rotate(-90deg);
        }
        &.top-middle {
          top: 100%;
          left: 50%;
          transform: translateX(-85px) rotate(180deg);
        }
        &.top-caret-left {
          top: 100%;
          left: 20%;
          transform: translateX(-30%) rotate(180deg);
        }
        &::after {
          content: '';
          position: absolute;
          height: ${caretHeight}px;
          width: ${caretHeight}px;
          transform: translateX(50%) translateY(50%) rotate(45deg);
          background-color: ${colors.white.shade1};
          box-shadow: 0 5px 45px rgba(0, 0, 0, 0.07);
        }
      }
      .cross-button {
        position: absolute;
        top: 3px;
        right: 3px;
        width: 8.135px;
        height: 8.135px;
        background-image: url('${svgToDataUri(icons.crossIcon)}');
      }
    }

    &:hover {
      .tooltipContainer {
        display: block;
      }
      .pointer {
        display: block;
      }
    }
    &.fullWidth {
      .tooltipContainer {
        margin: 0;
        box-shadow: 0 5px 45px rgba(0, 0, 0, 0.24);
        &.bottom {
          left: 0;
          transform: translateX(0);
        }
        &.bottom-left-1 {
          top: 54px;
          left: 0;
          transform: translateX(0);
        }
      }
      .pointer {
        position: relative;
        &::before {
          border-color: transparent transparent ${colors.white.shade1};
          content: '';
          height: 15px;
          left: -20px;
          top: 25px;
          background-color: ${colors.white.shade1};
          width: 15px;
          z-index: ${zIndex.tooltip.pointer};
          position: absolute;
          transform: rotate(45deg);
        }
      }
    }
  }
`;

export const TooltipTextStyles = css`
  .tooltipText {
    width: 100%;
    padding: 12px 16px;
    line-height: 21px;
    font-size: ${typography.base};
    color: ${colors.black.shade1};
    font-weight: 400;
    overflow-wrap: break-word;
    @mixin mobileToTablet {
      padding: 14px 16.5px;
    }
    &.default {
      width: 600px;
    }
    &.medium {
      width: 381px;
      @mixin mobileToTablet {
        width: 337px;
      }
    }
    &.small {
      width: 295px;
    }
    &.x-small {
      width: 165.13px;
      height: 48.996px;
      flex-shrink: 0;
      padding: 4px;
      color: #767676;
      font-family: Lato, sans-serif;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
`;
