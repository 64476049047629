import apiConfig from '../../config/api/apiConfig';
import CookieStorage from '../CookieStorage';
import _ from '../LodashImports';
import Logger from '../Logger';
import httpService from '../httpService';
import { getCommerceHeaders } from '../tenantHelper';
import { mapProduct, segregateCollection } from './helpers';

const removeCollectionCommerce =
  ({ cart, setCart }) =>
  async ({ appCtx, products }) => {
    const { regionCode } = appCtx;
    const cartId = CookieStorage.getCookie('cartId');
    const { OFFER_PRICE_LABEL, NORMAL_PRICE_LABEL } = _.get(appCtx, 'siteConfig.textDictionary', {});
    const toggles = _.get(appCtx, 'siteConfig.toggles', {});

    const patchCart = products.map(({ cartLineId, totalQuantity }) => ({
      op: 'replace',
      path: `${cartLineId}/quantityNumber`,
      value: totalQuantity - 1,
    }));
    const reqBody = {
      data: {
        cart: {
          patchCart,
        },
      },
      metadata: {
        politicalAreaId: appCtx.politicalId,
        priceGroup: appCtx.priceGroupId,
      },
    };

    const additionalHeaders = getCommerceHeaders(regionCode, true, appCtx.url, appCtx.store);

    const patchCartLineUrl = apiConfig.patchCartlineCommerceUrl(cartId, regionCode);

    const { data: response, statusCode } = await httpService().patch(
      patchCartLineUrl,
      reqBody,
      {
        credentials: 'include',
      },
      additionalHeaders,
      'json'
    );

    const items = _.get(response, 'data.cart.cartLines', []);
    const totalProductsQuantityCount = _.get(response, 'data.cart.totalProductsQuantityCount', 0);
    const totalProductsCount = totalProductsQuantityCount;

    if (statusCode === 200 && items) {
      const mappedProducts = items.map((item) =>
        mapProduct(item, regionCode, { OFFER_PRICE_LABEL, NORMAL_PRICE_LABEL }, toggles)
      );
      const mappedItems = segregateCollection({
        mappedProducts,
        collection: cart.typeACollections,
        action: 'REMOVE',
        products: [],
        cartItems: cart.cartItems,
      });

      setCart({
        ...cart,
        ...mappedItems,
        totalProductsCount,
      });
    } else {
      const { errors } = response;
      Logger.error(`Failed to Remove Product ${JSON.stringify(products)}`);
      return errors || [];
    }
    return null;
  };

export default removeCollectionCommerce;
