import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import constants from '../../config/constants';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withConfig } from '../../utils/ConfigurationContext';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import {
  navigateWithUrl,
  navigateWithUrlForBrand,
  navigateWithUrlForCategory,
  navigateWithUrlForCollection,
  navigateWithUrlForSeller,
} from '../../utils/RouteHandler';
import WithRouter from '../../utils/WithRouter';
import noop from '../../utils/noop';
import MatchMedia from '../ui/MatchMedia/MatchMedia';
import FacetsDesktop from './Desktop/FacetsDesktop';
import { facetFilters } from './FacetEvents';
import DynamicFacets from './Mobile/DynamicFacets';
import FacetsMobile from './Mobile/FacetsMobile';
import { getfacetsWithUpdatedBrandValues } from './VerMasFacet/VerMasFacetHelper';

const Facets = (props) => {
  const {
    baseFacetsURL,
    appCtx,
    sortOptions,
    data: initialData,
    availabilityFacets,
    labels,
    pageName,
    onFacetChange,
    config,
    handleScrollToTopProductList,
    appliedFacets,
    pagination,
    canonicalUrl,
  } = props;
  const [data, setData] = useState(initialData);
  const [isFiltersMenuVisible, setIsFiltersMenuVisible] = useState(false);
  const mobileTitle = labels.FILTERS_TITLE_MOBILE;
  const dropDownLabel = labels.ORDER_BY_MOBILE;
  const isFacetWithSwitchVisible = _.get(appCtx, 'siteConfig.toggles.isFacetWithSwitchVisible', false);
  const isEventSwitchVisible = _.get(appCtx, 'siteConfig.toggles.isEventToggleVisible', false);
  const isDynamicFacetsEnabled = appCtx?.siteConfig?.toggles?.isDynamicFacetsEnabled;

  const { tenant, setBusy, store, regionCode } = appCtx;

  const hideMenu = useRef();

  const id = WithRouter('id');
  const name = WithRouter('name');
  const isPLP = WithRouter('isPLP');
  const Ntt = WithRouter('Ntt');
  const xlpId = pageName === 'search' ? Ntt : id;

  const selectedURLs = {};
  const selectedURLsForAvailability = {};

  const getUpdatedFacets = async (facets) => {
    return getfacetsWithUpdatedBrandValues(facets, {
      config,
      appCtx,
      xlpName: pageName,
      xlpId,
    });
  };

  useEffect(() => {
    facetFilters(initialData.concat(availabilityFacets));
    setData(initialData);
  }, [initialData, availabilityFacets]);

  const changeURL = (rawUrl, hideMenuOnChange = true) => {
    let url = rawUrl;
    if (hideMenu.current && hideMenuOnChange) {
      hideMenu.current();
    }

    if (
      url.indexOf('layout=') === -1 &&
      appCtx.url &&
      appCtx.url.includes('layout=') &&
      appCtx.url.split('layout=')[1]
    ) {
      url = `${url}&layout=${appCtx.url.split('layout=')[1][0]}`;
    }

    if (pageName === 'category') {
      navigateWithUrlForCategory({
        site: tenant,
        url: isPLP && url.indexOf(isPLP) === -1 ? `${url}&isPLP=1` : url,
        onRouteChange: () => {
          setBusy(true);
        },
        categoryId: id,
        categoryName: name,
        store,
        regionCode,
      });
    } else if (pageName === 'collection') {
      navigateWithUrlForCollection({
        site: tenant,
        url,
        onRouteChange: () => {
          setBusy(true);
        },
        collectionId: id,
        store,
        regionCode,
      });
    } else if (pageName === 'seller') {
      navigateWithUrlForSeller({
        regionCode,
        site: tenant,
        url,
        onRouteChange: () => {
          setBusy(true);
        },
        sellerName: id,
        store,
      });
    } else if (pageName === 'brand') {
      navigateWithUrlForBrand({
        regionCode,
        site: tenant,
        url,
        onRouteChange: () => {
          setBusy(true);
        },
        brandName: id,
        store,
      });
    } else {
      navigateWithUrl({
        site: tenant,
        url,
        onRouteChange: () => {
          setBusy(true);
        },
        store,
        regionCode,
      });
    }
  };

  const renderMobileFacets = () => {
    return (
      <>
        <FacetsMobile
          onHideMenu={(hideMenuFacets) => {
            hideMenu.current = hideMenuFacets;
          }}
          data={data}
          dataMethods={{
            type: 'availability',
            tenant,
            baseFacetsURL,
            changeURL,
          }}
          availabilityFacets={availabilityFacets}
          mobileTitle={mobileTitle}
          title={labels.FILTERS_TITLE_REBRANDING_MOBILE}
          sortOptions={sortOptions}
          dropDownLabel={dropDownLabel}
          selectedURLs={selectedURLs}
          pageName={pageName}
          selectedURLsForAvailability={selectedURLsForAvailability}
          onFacetChangeHandler={onFacetChange}
          getUpdatedFacets={getUpdatedFacets}
          appliedFacets={appliedFacets}
          pagination={pagination}
          handleScrollToTopProductList={handleScrollToTopProductList}
          isFiltersMenuVisible={isFiltersMenuVisible}
          setIsFiltersMenuVisible={setIsFiltersMenuVisible}
          canonicalUrl={canonicalUrl}
        />
        {isDynamicFacetsEnabled && (
          <DynamicFacets
            availabilityFacets={availabilityFacets}
            facets={data}
            tenant={tenant}
            baseFacetsURL={baseFacetsURL}
            changeURL={changeURL}
            pageName={pageName}
            onFacetChange={onFacetChange}
            store={store}
            appCtx={appCtx}
            getUpdatedFacets={getUpdatedFacets}
            labels={labels}
            setIsFiltersMenuVisible={setIsFiltersMenuVisible}
          />
        )}
      </>
    );
  };

  const desktop = () => (
    <>
      {isFacetWithSwitchVisible && (
        <FacetsDesktop
          data={data.filter((d) => constants.FREE_SHIPPING_REGEX.test(d.name))}
          dataMethods={{
            type: 'facetsWithSwitch',
            tenant,
            baseFacetsURL,
            changeURL,
          }}
          pageName={pageName}
          type="facetsWithSwitch"
          onFacetChangeHandler={onFacetChange}
        />
      )}
      {isEventSwitchVisible && (
        <FacetsDesktop
          data={data.filter((d) => constants.EVENT_REGEX.test(d.type))}
          dataMethods={{
            type: 'facetsWithSwitch',
            tenant,
            baseFacetsURL,
            changeURL,
          }}
          pageName={pageName}
          type="facetsWithSwitch"
          onFacetChangeHandler={onFacetChange}
        />
      )}
      {availabilityFacets.length > 0 && (
        <FacetsDesktop
          data={availabilityFacets.filter((d) => constants.SHIPPING_GROUP_REGEX.test(d.group))}
          dataMethods={{
            type: 'availability',
            tenant,
            baseFacetsURL,
            changeURL,
          }}
          pageName={pageName}
          type="availability"
          baseFacetsURL={baseFacetsURL}
          onFacetChangeHandler={onFacetChange}
        />
      )}
      <FacetsDesktop
        data={
          isFacetWithSwitchVisible
            ? data.filter((d) => {
                if (isEventSwitchVisible) {
                  return !constants.FREE_SHIPPING_REGEX.test(d.name) && !constants.EVENT_REGEX.test(d.type);
                }
                return !constants.FREE_SHIPPING_REGEX.test(d.name);
              })
            : data.filter((d) => {
                if (isEventSwitchVisible) {
                  return !constants.EVENT_REGEX.test(d.type);
                }
                return true;
              })
        }
        dataMethods={{
          type: '',
          tenant,
          baseFacetsURL,
          changeURL,
        }}
        pageName={pageName}
        type=""
        onFacetChangeHandler={onFacetChange}
        getUpdatedFacets={getUpdatedFacets}
        canonicalUrl={canonicalUrl}
      />
    </>
  );

  return (
    <MatchMedia
      content={{
        mobile: () => renderMobileFacets(),
        tablet: () => renderMobileFacets(),
        desktop,
      }}
    />
  );
};

Facets.defaultProps = {
  labels: {},
  appliedFacets: [],
  pageName: 'search',
  onFacetChange: noop,
  pagination: {},
  canonicalUrl: '',
};

Facets.propTypes = {
  baseFacetsURL: PropTypes.string.isRequired,
  appCtx: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  sortOptions: PropTypes.array.isRequired,
  availabilityFacets: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  labels: PropTypes.object,
  pageName: PropTypes.string,
  onFacetChange: PropTypes.func,
  handleScrollToTopProductList: PropTypes.func.isRequired,
  appliedFacets: PropTypes.array,
  pagination: PropTypes.object,
  canonicalUrl: PropTypes.string,
};

export { Facets };
export default withApplicationContext(withLabels(withConfig(Facets)));
