import PropTypes from 'prop-types';
import React from 'react';
import { getFacetLink } from '../../FacetsLink/facetLinkUtils';
import { styles } from './FacetWithLink.style';

const FacetWithLink = ({ isLink, canonicalUrl, option, children }) => {
  if (!(isLink && canonicalUrl)) {
    return children;
  }
  const handleClick = (event) => {
    event.preventDefault();
  };
  const facetLink = getFacetLink(canonicalUrl, option);
  return (
    <a href={facetLink} className="facet-link" onClick={handleClick} data-testid="facet-link">
      {children}
      <style jsx>{styles}</style>
    </a>
  );
};

FacetWithLink.propTypes = {
  isLink: PropTypes.bool,
  canonicalUrl: PropTypes.string,
  option: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

FacetWithLink.defaultProps = {
  isLink: false,
  canonicalUrl: '',
};

export { FacetWithLink };
