import _ from '../../../utils/LodashImports';

const getServicesBenefits = ({ siteConfig = {}, regionCode = 'cl' }) => {
  const fallbackServicesList = {
    cl: [
      'Armado de muebles: Cobertura en todo Chile a excepción de zonas rurales.',
      'Instalación: La cobertura dependerá del producto.',
      'Retiro y reciclaje: Cobertura en Región Metropolitana, V región y VI región.',
      'Producto protegido: Protege tu producto durante 120 días contra robo o daño accidental. Cobertura en todo Chile.',
    ],
    pe: [
      'Armado de muebles: Cobertura en todo Chile a excepción de zonas rurales.',
      'Instalación: La cobertura dependerá del producto.',
      'Retiro y reciclaje: Cobertura en Región Metropolitana, V región y VI región.',
    ],
    co: [
      'Armado de muebles: Cobertura en todo Chile a excepción de zonas rurales.',
      'Instalación: La cobertura dependerá del producto.',
      'Retiro y reciclaje: Cobertura en Región Metropolitana, V región y VI región.',
    ],
  };
  const servicesBenefits = _.get(siteConfig, `configurations.servicesBenefits`, fallbackServicesList[regionCode]);

  return servicesBenefits.map((benefit) => {
    const [title, description] = benefit.split(':');
    return { title, description };
  });
};

export { getServicesBenefits };
