import { isTottusStandalone } from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../config/constants';
import { withApplicationContext } from '../../utils/ApplicationContext';
import {
  navigateWithUrl,
  navigateWithUrlForBrand,
  navigateWithUrlForCategory,
  navigateWithUrlForCollection,
  navigateWithUrlForSeller,
} from '../../utils/RouteHandler';
import WithRouter from '../../utils/WithRouter';
import BodyCopy from '../ui/BodyCopy/BodyCopy';
import Chip from '../ui/Chip/Chip';
import { setDigitalData } from './helpers';
import SelectedFiltersStyles from './SelectedFilters.style';

const SelectedFilters = ({ filters, selectedFiltersLabel, appCtx, baseFacetsURL, pageName, facets }) => {
  const { tenant, setBusy, store, regionCode } = appCtx;
  const isTottusSA = isTottusStandalone(store);
  const id = WithRouter('id');
  const name = WithRouter('name');

  const getSelectedFacetsLabels = () => {
    const facetLabelMap = {};
    facets.forEach((facet) => {
      const { facetType } = facet && facet.values && facet.values[0] ? facet.values[0] : '';
      facetLabelMap[`${facetType}`] = facet && facet.name ? facet.name : '';
    });
    return facetLabelMap;
  };

  const handleChipClick = (url, clickedType, clickedValue) => {
    const urls = [baseFacetsURL];
    if (url) {
      const facetURL = url.split('&').filter((u) => !u.match(/facetSelected/g));
      if (facetURL.length > 0) {
        urls.push('facetSelected=true', facetURL.join('&'));
      }
    } else if (!url && !!store) {
      urls.push(`store=${store}`);
    }
    if (
      url.indexOf('layout=') === -1 &&
      appCtx.url &&
      appCtx.url.includes('layout=') &&
      appCtx.url.split('layout=')[1]
    ) {
      urls.push(`layout=${appCtx.url.split('layout=')[1][0]}`);
    }
    if (pageName === 'category') {
      navigateWithUrlForCategory({
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url: urls.join('&'),
        categoryId: id,
        categoryName: name,
        store,
        regionCode,
      });
    } else if (pageName === 'collection') {
      navigateWithUrlForCollection({
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url: urls.join('&'),
        collectionId: id,
        store,
        regionCode,
      });
    } else if (pageName === 'seller') {
      navigateWithUrlForSeller({
        regionCode,
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url: urls.join('&'),
        sellerName: id,
        store,
      });
    } else if (pageName === 'brand') {
      navigateWithUrlForBrand({
        regionCode,
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url: urls.join('&'),
        brandName: id,
        store,
      });
    } else {
      navigateWithUrl({
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url: urls.join('&'),
        store,
        regionCode,
      });
    }
    const facetLabels = getSelectedFacetsLabels();
    const facetLabel = facetLabels[`${clickedType}`];
    setDigitalData(facetLabel, clickedValue);
  };

  const getValue = (label, attributes) => {
    if (attributes && attributes.unit && label.split('-').length > 1) {
      const { unit } = attributes;
      return `${unit}${label.split('-').join(` - ${unit}`)}`;
    }
    return label;
  };

  const getIconClass = (facetType) => {
    return constants.ICON_CLASSES_MAPPING[facetType] ?? '';
  };

  return (
    <>
      <div className="selected-filters">
        <div className="filter-container" id="testId-SelectedFilters-container">
          <div className="filter-title">
            <BodyCopy size="copy1" isHighlighted>
              {selectedFiltersLabel}
            </BodyCopy>
            <button
              type="button"
              className="removeAll-desktop"
              id="testId-selectedFilters-removeAll-desktop"
              onClick={() => handleChipClick('')}
            >
              <span>
                <i className="csicon-danger" />
              </span>
            </button>
          </div>
          <div className="chips">
            {filters.map((val) => (
              <Chip
                value={getValue(val.title, val.attributes)}
                key={`${val.title}-${val.facetValue}`}
                onChipClick={handleChipClick}
                url={val.url}
                type={isTottusSA ? 'tottus' : 'mkp'}
                facetType={val.facetType}
                facetValue={val.facetValue}
                iconClass={getIconClass(val.facetType)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="divider-line-container">
        <div className="divider-line" />
      </div>
      <style jsx>{SelectedFiltersStyles}</style>
    </>
  );
};

SelectedFilters.defaultProps = {
  baseFacetsURL: '',
};

SelectedFilters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedFiltersLabel: PropTypes.string.isRequired,
  appCtx: PropTypes.object.isRequired,
  baseFacetsURL: PropTypes.string,
  pageName: PropTypes.string.isRequired,
  facets: PropTypes.array.isRequired,
};

export { SelectedFilters };
export default withApplicationContext(SelectedFilters);
