import css from 'styled-jsx/css';
import { colors, typography } from '../../../../config/styles';

const SizeButtonStyles = css`
  .size-button {
    padding: 9px 10px;
    box-sizing: border-box;
    min-width: 40px;
    height: 40px;

    &.rebranded {
      @mixin mobileToTablet {
        font-size: ${typography.lg1};
      }
      @mixin tabletToDesktop {
        font-size: ${typography.sm1};
      }
    }

    @mixin desktop {
      min-width: 40px;
      height: 40px;
      line-height: 16px;
      padding: 12px;
    }

    font-family: inherit;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    border-radius: 4px;
    text-align: center;
    margin: 0 8px 8px 0;
    color: ${colors.black.shade7};
    border: 1px solid ${colors.gray.shade58};
    background-color: ${colors.white.shade1};

    &-selected {
      color: ${colors.green.shade1};
      border-color: ${colors.green.shade12};
      background-color: ${colors.green.shade13};
      &.rebranded {
        color: ${colors.black.shade10};
        border: 1px solid var(--primary-color);
        background-color: ${colors.gray.shade59};
        border-radius: 4px;
        @mixin tabletToDesktop {
          padding: 12px;
        }
      }
    }

    &-unavailable {
      position: relative;
      color: ${colors.gray.shade33};
      border: 1px solid ${colors.gray.shade36};
      background-color: inherit;
      background: linear-gradient(to bottom right, #fff calc(50% - 1px), ${colors.gray.shade36}, #fff calc(50% + 1px));
    }

    &-withText {
      padding: 0.5px;
    }

    &-text {
      font-size: ${typography.xs1};
    }
  }
`;

export { SizeButtonStyles };
