import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { icons } from '../../config/styles';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { STORE_FALLBACK_COLORS } from '../../utils/customCssHelper';
import { svgToDataUri } from '../../utils/styles/svgMixins';

const DynamicFacetsBar = ({ groups, onSelect, store, selectedKey, isOpen, showPlus, setIsFiltersMenuVisible }) => {
  const isActive = (group) => group.facets.some((g) => g.values.some((v) => v.selected));
  const sisColor = STORE_FALLBACK_COLORS[store || 'tienda'];
  return (
    <div className="dynamic-facets-bar">
      <div className="dynamic-facets-bar-scroll">
        {groups.map((group) => (
          <button
            type="button"
            key={group.name}
            className={cn('dynamic-facets-bar-button', {
              active: isActive(group),
              'event-button': group.key === 'event',
            })}
            onClick={() => onSelect(group)}
          >
            {group.icon && <i className={cn('dynamic-facets-bar-icon', group.icon)} />}
            <span>{group.name}</span>
            {group.key !== 'event' &&
              (selectedKey === group.key && isOpen ? (
                <i className="csicon-arrow_up_small" />
              ) : (
                <i className="csicon-arrow_down_small" />
              ))}
          </button>
        ))}
        {showPlus && (
          <button
            type="button"
            key="plus"
            className="dynamic-facets-bar-button"
            onClick={() => {
              setIsFiltersMenuVisible(true);
            }}
          >
            +
          </button>
        )}
      </div>

      <style jsx>{`
        .dynamic-facets-bar {
          width: 100%;
          overflow-x: scroll;
          border-top: 1px solid #dedede;
        }
        .dynamic-facets-bar-scroll {
          display: flex;
          flex-wrap: nowrap;
          padding: 12px 18px;
          gap: 8px;
        }
        .dynamic-facets-bar-button {
          display: flex;
          align-items: center;
          padding: 8px;
          gap: 4px;
          border-radius: 8px;
          border: solid 1px #a9b5c0;
          font-weight: 700;

          &.event-button {
            padding-right: 10px;
          }
          span {
            white-space: nowrap;
          }
          i {
            font-size: 10px;
          }
        }
        .dynamic-facets-bar-button.active {
          border-color: ${sisColor};
        }
        .csicon-arrow_down_small,
        .csicon-arrow_up_small {
          margin-left: 5px;
        }
        .csicon-disponibilidad-mkp {
          margin-right: 4px;
          width: 24px;
          font-size: 16px;
          height: 20px;
          background-image: url('${svgToDataUri(icons.shippingIcon)}');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        .csicon-event {
          margin-right: 0px;
          width: 24px;
          font-size: 16px;
          height: 20px;
          background-image: url('${svgToDataUri(icons.event)}');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
      `}</style>
    </div>
  );
};

DynamicFacetsBar.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({})),
  onSelect: PropTypes.func,
  store: PropTypes.string,
  selectedKey: PropTypes.string,
  isOpen: PropTypes.bool,
  showPlus: PropTypes.bool.isRequired,
  setIsFiltersMenuVisible: PropTypes.func.isRequired,
};

DynamicFacetsBar.defaultProps = {
  groups: [],
  onSelect: () => {},
  selectedKey: '',
  isOpen: false,
  store: '',
};

export default withApplicationContext(DynamicFacetsBar);
