import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withLabels } from '../../../utils/LabelsContext';
import noop from '../../../utils/noop';
import InputBox from '../../ui/InputBox/InputBox';
import MarketplaceSpinner from '../../ui/Spinner/MarketplaceSpinner';
import { digitalData } from '../Common/digitalData';
import VerMasButton from './VerMasButton/VerMasButton';
import VerMasFacetStyle from './VerMasFacet.style';
import VerMasFacetCheckbox from './VerMasFacetCheckbox/VerMasFacetCheckbox';
import VerMasFacetModal from './VerMasFacetModal/VerMasFacetModal';

const VerMasFacet = ({ appCtx, labels, facet: initialFacet, onFacetChange, isFacetCountHidden, getUpdatedFacets }) => {
  const { deviceType } = appCtx;
  const [facet, setFacet] = useState(initialFacet);
  const [facetWithAllValues, setFacetWithAllValues] = useState(null);
  const { values, name, isSearchable } = facet;
  const [visible, setVisible] = useState(false);
  const [displayValues, setDisplayValues] = useState(facet.values.slice(0, 7));
  const [searchedValues, setSearchedValues] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleViewResults = (url) => {
    onFacetChange({ url });
    setVisible(!visible);
  };

  useEffect(() => {
    setDisplayValues(searchedValues || values.slice(0, deviceType !== 'desktop' && visible ? values.length : 7));
  }, [searchedValues, values, visible]);

  const handleSearch = () => {
    if (!searchTerm) {
      return setSearchedValues(null);
    }
    const filteredValues = values.filter((value) => value.facetValue.toLowerCase().includes(searchTerm.toLowerCase()));
    return setSearchedValues(filteredValues);
  };

  useEffect(() => {
    handleSearch();
  }, [searchTerm, values]);

  useEffect(() => {
    // eslint-disable-next-line consistent-return, array-callback-return
    initialFacet.values.some((element) => {
      if (element.selected) {
        setFacetWithAllValues(initialFacet);
        return true;
      }
      setFacetWithAllValues(null);
    });
    setFacet(initialFacet);
  }, [initialFacet]);

  const viewMoreValues = async () => {
    if (!loading && !facetWithAllValues) {
      setLoading(true);
      const updatedFacet = await getUpdatedFacets([facet]);
      setFacet(updatedFacet[0]);
      setFacetWithAllValues(updatedFacet[0]);
      setLoading(false);
    } else {
      setFacetWithAllValues(facet);
    }
  };

  const handleViewMoreClick = () => {
    if (!visible) {
      viewMoreValues();
    }
    setVisible(!visible);
    digitalData('1', true);
  };

  return (
    <>
      {isSearchable && (
        <div className="searchBox">
          <InputBox
            hideLabel
            label={labels.SEARCH_IN_FACETS_LABEL}
            onChange={setSearchTerm}
            onFocus={viewMoreValues}
            id={`testId-verMas-facet-${name}`}
          />
        </div>
      )}
      <ul className={`ver-mas-container${searchedValues ? ' scroll' : ''}`}>
        {displayValues.map((option) => {
          return (
            <li key={`testId-${option.title}`} id={`testId-${option.title}`} className="sidebar-facet-item">
              <VerMasFacetCheckbox
                isLink={facet?.isLink}
                canonicalUrl={facet?.canonicalUrl}
                option={option}
                onChange={() => {
                  onFacetChange(option, !searchTerm);
                  if (searchTerm) {
                    digitalData(`${name}|${searchTerm}|${option.title}`);
                  }
                }}
                isFacetCountHidden={isFacetCountHidden}
              />
            </li>
          );
        })}
      </ul>
      {loading && (
        <div className="loader">
          <MarketplaceSpinner size="small" />
        </div>
      )}
      <VerMasButton
        onClick={handleViewMoreClick}
        label={deviceType !== 'desktop' && visible ? labels.SEE_LESS_FACET_VALUES : labels.SEE_MORE_FACET_VALUES}
        hidden={!!searchedValues || displayValues.length < 7}
      />
      <VerMasFacetModal
        key={`verMas-modal-${facet.name}`}
        visible={visible}
        setVisible={setVisible}
        facet={{
          ...facet,
        }}
        hidden={deviceType !== 'desktop'}
        handleViewResults={handleViewResults}
        appCtx={appCtx}
        loading={loading}
      />
      <style jsx>{VerMasFacetStyle}</style>
    </>
  );
};

VerMasFacet.propTypes = {
  facet: PropTypes.object.isRequired,
  appCtx: PropTypes.object,
  labels: PropTypes.object,
  onFacetChange: PropTypes.func.isRequired,
  isFacetCountHidden: PropTypes.bool,
  getUpdatedFacets: PropTypes.func,
};

VerMasFacet.defaultProps = {
  appCtx: {},
  labels: {},
  isFacetCountHidden: true,
  getUpdatedFacets: noop,
};

export { VerMasFacet };
export default withApplicationContext(withLabels(VerMasFacet));
