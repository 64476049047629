import getConfig from 'next/config';
import constants from '../../config/constants';
import { appendScriptsToHead, createScriptTag } from '../../utils/DOMUtil';

const nextConfig = getConfig();
let { env } = (nextConfig && nextConfig.publicRuntimeConfig) || {
  env: 'dev',
};
if (env === 'uat') {
  env = 'beta';
}

const loadDigitalData = (route) => {
  let scriptURL = null;
  if (
    [
      '/category',
      '/lista',
      '/search',
      '/buscar',
      '/collection',
      '/seleccion',
      '/seller',
      '/vendedor',
      '/brand',
      '/marca',
    ].includes(route)
  ) {
    scriptURL = constants.DIGITAL_DATA_MANAGER_XLP_SCRIPT[env];
  }
  if (['/noResult'].includes(route)) {
    scriptURL = constants.DIGITAL_DATA_MANAGER_NO_RESULT_SCRIPT[env];
  }
  if (scriptURL) {
    const dynamicScript = createScriptTag({
      src: scriptURL,
      id: 'script-digital-data-manager',
    });
    appendScriptsToHead(dynamicScript);
  }
};

export default loadDigitalData;
