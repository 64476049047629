import { isStandalone } from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import apiConfig from '../config/api/apiConfig';
import constants from '../config/constants';
import { storeMap } from '../config/storeMap';
import ProductLandingContainer from '../containers/ProductLanding/ProductLandingContainer';
import { withApplicationContext } from '../utils/ApplicationContext';
import CartContext from '../utils/CartContext';
import { withConfig } from '../utils/ConfigurationContext';
import _ from '../utils/LodashImports';
import { navigate, redirect } from '../utils/RouteHandler';
import SellerSEO from '../utils/SEO/SellerSEO';
import WebStorage from '../utils/WebStorage';
import WithUrl from '../utils/WithUrl';
import { addProductListEvent } from '../utils/dataLayerHelper';
import { isWindow } from '../utils/deviceChecker';
import { useOnScroll } from '../utils/hooks/UseOnScroll';
import httpService from '../utils/httpService';
import { handleScroll } from '../utils/paginationUtils';
import cleanText from '../utils/textHelper';
import { getCanonicalText, getPaginatedUrl as getPaginatedUrlUtil, getRequestUrl } from '../utils/urlHelper';
import Error from './_error';

const updateLocation = () => {
  WebStorage.setItem({
    name: constants.SESSION_STORAGE_SCROLL,
    value: `${window.scrollX},${window.scrollY}`,
  });
};

const Seller = (props) => {
  const { statusCode } = props;
  // eslint-disable-next-line react/prop-types
  const { appCtx, config, ...initialData } = props;
  const { regionCode, host, store } = appCtx;

  const data = useRef();
  const isInitialDataEmpty = Object.entries(initialData).length === 0;
  if (!isInitialDataEmpty) {
    data.current = initialData;
  }

  const {
    searchTerm,
    pagination,
    facets,
    baseFacetsURL,
    appliedFacets,
    autoCorrections,
    availabilityFacets,
    results,
    sortOptions,
    currentLocationId,
    metadata,
    canonicalUrl,
    site,
    layout,
    categoryBannerHtml,
    currentUrl,
    promotedFilter,
    leftPromotionContent,
  } = data.current || {};

  useEffect(() => {
    handleScroll(appCtx);
    if (results) {
      addProductListEvent(results, 'seller', store, regionCode);
    }
  }, [results]);

  useOnScroll({
    handler: updateLocation,
  });

  const getPaginatedUrl = (paginationIndex) => {
    return getPaginatedUrlUtil(currentUrl, paginationIndex, store);
  };

  if (statusCode === 500) {
    return <Error />;
  }

  return (
    <>
      <SellerSEO
        SiteProps={{
          regionCode,
          metadata,
          canonicalUrl,
          host,
          store,
          facets,
        }}
      />
      <CartContext.Provider>
        <ProductLandingContainer
          pagination={{
            data: pagination,
            getPaginatedUrl,
          }}
          layout={layout}
          products={results}
          promotedFilter={promotedFilter}
          autoCorrections={autoCorrections}
          facets={facets}
          baseFacetsURL={baseFacetsURL}
          appliedFacets={appliedFacets}
          searchTerm={searchTerm}
          availabilityFacets={availabilityFacets}
          sortOptions={sortOptions}
          site={site}
          currentLocationId={currentLocationId}
          brandDetails={metadata}
          categoryBannerHtml={categoryBannerHtml}
          leftPromotionContent={leftPromotionContent}
          pageName="seller"
          canonicalUrl={canonicalUrl}
        />
      </CartContext.Provider>
    </>
  );
};

const shouldRedirect = (responseType) => {
  return responseType === constants.SEARCH_REDIRECT_STATUS;
};

const redirectSeller = (response, url, redirectCode = constants.HTTP_RES_CODE.TEMP_REDIRECT) => {
  if (isWindow()) {
    redirect(url, true);
    return;
  }

  if (response) {
    response.writeHead(redirectCode, { Location: url });
    response.end();
  }
};

const redirectNoSearchResult = (response, site, regionCode, termo, store) => {
  if (isWindow()) {
    navigate({
      url: '/noResult',
      params: {
        Ntt: termo,
      },
      site,
    });
    return;
  }

  if (response) {
    const url = `/${storeMap[store] || 'falabella'}-${regionCode}/noResult?Ntt=${termo}`;
    response.writeHead(constants.HTTP_RES_CODE.TEMP_REDIRECT, { Location: url });
    response.end();
  }
};

const getUrlToRedirect = (currentUrl, canonicalUrl) => {
  const queryStrings = currentUrl.split('?')[1];
  const params = queryStrings ? `?${queryStrings}` : '';
  return `${encodeURI(canonicalUrl)}${params}`;
};

const getCanonicalUrl = (siteId, sellerName, query, store) => {
  const { page } = query;
  const storeValidated = isStandalone({ store }) ? store : null;
  const cleanName = getCanonicalText(sellerName);
  const url = WithUrl(
    isStandalone({ store }) ? 'SA_SELLER_PAGE' : 'SELLER_PAGE',
    siteId,
    {
      custom: {
        sellerName: cleanName,
      },
    },
    storeValidated
  );

  if (page > 1) {
    return `${url}?page=${page}`;
  }
  return url;
};

const isCanonicalUrl = (currentUrl, metadataName) => {
  const cleanName = cleanText(metadataName);
  const urlPath = currentUrl.match('(/{0,1}[^?#]*)')[0].split('/');
  const currentName = urlPath[urlPath.length - 1];
  return currentName === cleanName;
};

const shouldSEORedirect = (appCtx, currentUrl, metadataName) => {
  const isRedirect301EnabledForSeller = _.get(appCtx, 'siteConfig.toggles.isRedirect301EnabledForSeller', false);

  return isRedirect301EnabledForSeller && !isCanonicalUrl(currentUrl, metadataName);
};

Seller.getInitialProps = async ({ config }, { query, req, res, asPath }, appCtx) => {
  const { site, id, ...rest } = query;
  const { regionCode: siteId, store } = appCtx;

  rest.sellerName = id;

  rest.pgid = appCtx.priceGroupId;
  rest.pid = appCtx.politicalId;
  rest.zones = appCtx.zones;

  const searchTerm = encodeURIComponent(query.name);

  const sellerUrl = apiConfig.getSellerApiURL({
    base: isWindow() ? config.API_HOST : config.CLUSTER_API_HOST,
    siteId,
    params: rest,
  });

  const currentLocationId = appCtx.politicalId;

  const headersForProductSearch = isWindow() ? {} : { cookie: appCtx.ssrCookies };
  const response = await httpService(appCtx).get(
    sellerUrl,
    {
      includeTracing: true,
      reqId: req && req.id,
      headers: {
        'Content-Type': 'application/json',
        ...appCtx.tracingHeaders,
        ...headersForProductSearch,
      },
    },
    'json'
  );

  const { data, error: sellerApiErrors, statusCode } = response;
  if (sellerApiErrors && statusCode !== 409) {
    res.statusCode = 500;
    return {
      statusCode: 500,
    };
  }

  if (shouldRedirect(data.responseType)) {
    if (!isWindow() && res && !(res.finished || res.headersSent)) {
      res.setHeader('Cache-Control', 'no-cache');
    }
    redirectSeller(res, data.data.altUrl);
    return undefined;
  }

  const metadata = _.get(data, 'data.metadata', {});
  const canonicalUrl = getCanonicalUrl(siteId, metadata.displayName, rest, store);

  const currentUrl = isWindow() ? asPath : getRequestUrl(req.url, appCtx.tenant);

  const {
    data: {
      data: {
        results,
        layout,
        facets,
        appliedFacets,
        baseFacetsURL,
        availabilityFacets,
        sortOptions,
        pagination,
        autoCorrections,
        relatedKeywordBanner,
        promotedFilter,
        leftPromotionContent,
      },
    },
  } = response;

  if (res && results && results.length === 0) {
    if (!isWindow() && res && !(res.finished || res.headersSent)) {
      res.setHeader('Cache-Control', 'no-cache');
    }
    redirectNoSearchResult(res, site, siteId, '', store);
    return undefined;
  }

  if (!metadata.canonical && shouldSEORedirect(appCtx, currentUrl, metadata.displayName)) {
    if (!isWindow() && res && !(res.finished || res.headersSent)) {
      res.setHeader('Cache-Control', 'no-cache');
    }
    const urlToRedirect = getUrlToRedirect(currentUrl, canonicalUrl);
    redirectSeller(res, urlToRedirect, constants.HTTP_RES_CODE.PERM_REDIRECT);
    return undefined;
  }

  let categoryBannerHtml = '';
  if (relatedKeywordBanner) {
    if (config.FETCH_BANNERS_IN_SERVER_SIDE === false && relatedKeywordBanner.includes('assets.contentstack')) {
      categoryBannerHtml = relatedKeywordBanner;
    } else {
      const { data: htmlData, error } = await httpService().get(relatedKeywordBanner, {
        includeTracing: false,
        asText: true,
      });
      if (!error) {
        categoryBannerHtml = htmlData;
      }
    }
  }

  if (!isWindow() && res && !(res.finished || res.headersSent)) {
    res.setHeader('Cache-Tag', 'catalyst-SLP-v1');
    const cacheKeyMaxAge = 365 * 24 * 60 * 60;
    res.setHeader(
      'Set-Cookie',
      `CACHE_KEY=${appCtx.buildId}; max-age=${cacheKeyMaxAge}; Path=/${appCtx.tenant}/search`
    );
  }

  return {
    results,
    layout,
    facets,
    baseFacetsURL,
    appliedFacets,
    searchTerm,
    promotedFilter,
    pagination,
    sortOptions,
    autoCorrections,
    availabilityFacets,
    currentLocationId,
    site: encodeURIComponent(query.site),
    metadata,
    categoryBannerHtml,
    leftPromotionContent,
    canonicalUrl,
    currentUrl,
  };
};

Seller.propTypes = {
  layout: PropTypes.object.isRequired,
  searchTerm: PropTypes.string.isRequired,
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  facets: PropTypes.arrayOf(PropTypes.object).isRequired,
  appliedFacets: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  site: PropTypes.string.isRequired,
  appCtx: PropTypes.object.isRequired,
  sortOptions: PropTypes.array.isRequired,
  autoCorrections: PropTypes.array.isRequired,
  availabilityFacets: PropTypes.array.isRequired,
  baseFacetsURL: PropTypes.string.isRequired,
  currentLocationId: PropTypes.string.isRequired,
  categoryBannerHtml: PropTypes.string.isRequired,
  leftPromotionContent: PropTypes.string.isRequired,
  canonicalUrl: PropTypes.string.isRequired,
  currentUrl: PropTypes.string.isRequired,
  statusCode: PropTypes.number.isRequired,
};

export {
  Seller,
  getCanonicalUrl,
  isCanonicalUrl,
  redirectNoSearchResult,
  redirectSeller,
  shouldRedirect,
  shouldSEORedirect,
};

export default withApplicationContext(withConfig(Seller));
