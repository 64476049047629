const sortSizes = (sizes) => {
  return sizes.sort((size1, size2) => {
    const s1 = size1.sizeOrder;
    const s2 = size2.sizeOrder;
    return s1 > s2 ? 1 : -1;
  });
};

const getUniqueSizesForSLP = (variants = []) => {
  const mapper = {};
  let isAnyVariantAvailable = false;
  const sizes = variants
    .map((variant) => {
      const { value } = variant;
      const variantSize = value && value.toUpperCase();
      if (!value || mapper[variantSize]) {
        return undefined;
      }
      mapper[variantSize] = true;
      isAnyVariantAvailable = true;
      return {
        value: variantSize,
        ...variant,
      };
    })
    .filter(Boolean);

  if (!isAnyVariantAvailable) {
    return [];
  }

  return sortSizes(sizes);
};

export { getUniqueSizesForSLP };
